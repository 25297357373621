import { useRef, useEffect } from 'react'
import LoadingBar from 'react-top-loading-bar'
import Router from 'next/router'
import styled, { css, useTheme } from 'styled-components'
import { Box } from '@resident-advisor/design-system'

const useLoadingBar = () => {
  const ref = useRef()

  useEffect(() => {
    const handleStart = () => {
      if (ref.current) {
        ref.current.continuousStart()
      }
    }

    const handleComplete = () => {
      if (ref.current) {
        ref.current.complete()
      }
    }

    Router.events.on('routeChangeStart', handleStart)
    Router.events.on('routeChangeComplete', handleComplete)
    Router.events.on('routeChangeError', handleComplete)

    return () => {
      Router.events.off('routeChangeStart', handleStart)
      Router.events.off('routeChangeComplete', handleComplete)
      Router.events.off('routeChangeError', handleComplete)
    }
  }, [ref])

  return ref
}
const TopLoadingBar = () => {
  const loadingRef = useLoadingBar()
  const theme = useTheme()

  return (
    <StyledBox height={3}>
      <LoadingBar ref={loadingRef} height={3} color={theme?.colors?.accent} />
    </StyledBox>
  )
}

const StyledBox = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  ${({ theme }) =>
    css`
      z-index: ${theme.zIndex.topLoadingBar};
    `};
`

export default TopLoadingBar
