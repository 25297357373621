/* eslint-disable no-underscore-dangle */
import { captureError } from 'lib/logger'
import { parseCookies } from 'nookies'
import cookieNames from 'lib/cookieNames'
import { AppInitialProps, IGetInitialProps } from '..'

// Gets the initial props required for rendering the app
// using the official server which does not have modified req/res objects
const getInitialProps: IGetInitialProps = async ({ Component, ctx }) => {
  let pageProps = {}

  try {
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }
  } catch (error) {
    captureError(error)
  }

  const isServerSideRender = !!ctx.req

  const props = isServerSideRender
    ? await import('./getServerSideProps').then((mod) => mod.default(ctx)) // Dynamic import so that we don't increase the client bundle size
    : getClientSideProps()

  return { ...props, pageProps }
}

type OfficialServerGetInitialProps = Omit<AppInitialProps, 'pageProps'>

// On the client side we re-use values that were initially passed from the server
// and persisted in window.__NEXT_DATA__.props
const getClientSideProps = (): OfficialServerGetInitialProps => {
  const {
    areaId,
    eventsAreaId,
    serverTime,
    isBot,
    currentDomain,
    featureSwitches,
    user,
    hasRefreshToken,
    locale,
    messages,
  } = window.__NEXT_DATA__.props

  const contentLanguageCookie = parseCookies()[cookieNames.ra_content_language]

  return {
    areaId,
    eventsAreaId,
    serverTime,
    isBot,
    currentDomain,
    featureSwitches,
    user,
    hasRefreshToken,
    locale,
    messages,

    // On the client-side, contentLanguage should come from the cookie instead of the header
    contentLanguageCookie,
    contentLanguage: null,
  }
}

export type { OfficialServerGetInitialProps }
export default getInitialProps
