const DEFAULT_AREA_ID = 13

// Keep this in sync with the query in GetClosestAreaQuery.js
const DEFAULT_AREA = {
  id: '13',
  name: 'London',
  urlName: 'london',
  blurb: '',
  country: {
    id: '3',
    name: 'United Kingdom',
    urlCode: 'UK',
    requiresCookieConsent: true,
    __typename: 'Country',
  },
  __typename: 'Area',
}

module.exports.DEFAULT_AREA_ID = DEFAULT_AREA_ID
module.exports.DEFAULT_AREA = DEFAULT_AREA
