import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import animation from 'themes/animation'

const FadeIn = ({ transitions = animation.default, children, ...props }) => (
  <motion.div
    variants={{
      open: {
        opacity: 1,
        pointerEvents: 'auto',
        transition: transitions.open,
      },
      closed: {
        opacity: 0,
        pointerEvents: 'none',
        transition: transitions.closed,
      },
    }}
    {...props}
  >
    {children}
  </motion.div>
)

FadeIn.propTypes = {
  transitions: PropTypes.shape({
    open: PropTypes.shape(),
    closed: PropTypes.shape(),
  }),
  children: PropTypes.node.isRequired,
}

FadeIn.defaultProps = {
  transitions: animation.default,
}

export default FadeIn
