import { createContext, useReducer, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import { useRouter } from 'next/router'
import AD_TARGETING_KEYS from 'enums/ad-targeting-keys'

const actions = {
  SET_TARGETING: 'SET_TARGETING',
  SET_USER_TARGETING: 'SET_USER_TARGETING',
  CLEAR_PAGE_TARGETING: 'CLEAR_PAGE_TARGETING',
  SET_LOADING: 'SET_LOADING',
}

const reducer = (state, action) => {
  let targetingKeys

  switch (action.type) {
    case actions.SET_TARGETING:
      return {
        ...state,
        loading: false,
        ...action.payload.reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: stripUnsupportedCharacters(value),
          }),
          {}
        ),
      }
    case actions.SET_USER_TARGETING:
      return {
        ...state,
        ...mapValues(
          groupBy(action.payload.adTargeting, 'campaignCode'),
          (value) => value.map(({ targetId }) => targetId.toString())
        ),
        ...managedProfileTargeting(action.payload.managedProfiles),
      }
    case actions.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case actions.CLEAR_PAGE_TARGETING:
      targetingKeys = window?.googletag?.pubads?.()?.getTargetingKeys?.()
      if (targetingKeys !== undefined) {
        return { ...omit(state, targetingKeys), loading: true }
      }
      return { ...state, loading: true }
    default:
      return state
  }
}

const AdTargetingContext = createContext()

const AdTargetingContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { loading: true })
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () =>
      dispatch({ type: actions.CLEAR_PAGE_TARGETING })

    const handleRouteChangeComplete = () =>
      dispatch({ type: actions.SET_LOADING, payload: false })

    router.events.on('routeChangeStart', handleRouteChange)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [router.events])

  return (
    <AdTargetingContext.Provider value={{ state, dispatch }}>
      {children}
    </AdTargetingContext.Provider>
  )
}

AdTargetingContextProvider.propTypes = {
  children: PropTypes.node,
}

const useAdTargetingContext = () => useContext(AdTargetingContext)

const stripUnsupportedCharacters = (value) => {
  if (!value) {
    return ''
  }

  if (Array.isArray(value)) {
    return value.map((valueString) => stripUnsupportedCharacters(valueString))
  }

  return value.replace(/"|'|=|!|\+|#|\*|~|;|\^|\(|\)|<|>|\[|\]/, '')
}

const managedProfileTargeting = (managedProfiles) => {
  const targeting = {}
  if (managedProfiles?.hasArtists) targeting[AD_TARGETING_KEYS.IS_ARTIST] = '1'
  if (managedProfiles?.hasPromoters)
    targeting[AD_TARGETING_KEYS.IS_PROMOTER] = '1'
  if (managedProfiles?.hasLabels) targeting[AD_TARGETING_KEYS.IS_LABEL] = '1'
  return targeting
}

export {
  AdTargetingContextProvider,
  useAdTargetingContext,
  actions,
  reducer,
  stripUnsupportedCharacters,
}
