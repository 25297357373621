import { createContext, useContext, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { setCookie } from 'lib/cookieHelpers'
import cookieNames from 'lib/cookieNames'
import CONTENT_LANGUAGES from 'lib/contentLanguages'

const ContentLanguageContext = createContext()

const useContentLanguageContext = () => useContext(ContentLanguageContext)

const ContentLanguageProvider = ({
  children,
  contentLanguage,
  contentLanguageCookie,
}) => {
  useEffect(() => {
    if (contentLanguage) {
      setCookie(cookieNames.ra_content_language, contentLanguage)
    }
  }, [contentLanguage])

  const iso = contentLanguage || contentLanguageCookie

  const state = useMemo(
    () =>
      Object.values(CONTENT_LANGUAGES).find(
        (languageProps) => languageProps.iso === iso
      ) ?? CONTENT_LANGUAGES.ENGLISH,
    [iso]
  )

  return (
    <ContentLanguageContext.Provider value={state}>
      {children}
    </ContentLanguageContext.Provider>
  )
}

ContentLanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
  contentLanguageCookie: PropTypes.string,
  contentLanguage: PropTypes.oneOf(['en', 'ja']),
}

export { useContentLanguageContext, ContentLanguageProvider }
