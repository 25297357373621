import { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Alignment } from '@resident-advisor/design-system'
import { Portal } from 'react-portal'
import { RemoveScroll } from 'react-remove-scroll'
import useFocus from 'hooks/useFocus'
import useRouterRouteChange from 'hooks/useRouterRouteChange'
import usePageHeight from 'hooks/usePageHeight'
import { opacity as themeOpacity, zIndex as themeZIndex } from 'themes'
import { FadeInBackground } from 'components/shared/animation'
import GlobalStyle from 'components/generic/global-style'
import testIds from 'enums/testIds'
import ModalHeader from './ModalHeader'

const Modal = ({
  children,
  focus = false,
  toggle,
  zIndex = themeZIndex.modal,
  backgroundOpacity = themeOpacity.modalBackground,
  disableClickToggle = false,
  topOffset = '0',
  ...props
}) => (
  <Portal>
    <RemoveScroll removeScrollBar={false}>
      <ModalBackground
        focus={focus}
        toggle={toggle}
        zIndex={zIndex}
        backgroundOpacity={backgroundOpacity}
        disableClickToggle={disableClickToggle}
        topOffset={topOffset}
        {...props}
      >
        {children}
      </ModalBackground>
    </RemoveScroll>
  </Portal>
)

Modal.propTypes = {
  toggle: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  focus: PropTypes.bool,
  zIndex: PropTypes.number,
  backgroundOpacity: PropTypes.number,
  disableClickToggle: PropTypes.bool,
  topOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

const ModalBackground = ({
  children,
  focus,
  toggle,
  zIndex,
  disableClickToggle,
  backgroundOpacity,
  allowModalScrolling,
  topOffset,
  ...props
}) => {
  const height = usePageHeight()
  const focusRef = useFocus(focus)

  useRouterRouteChange(toggle)

  const handleClick = useCallback(
    (e) => {
      // target is the element clicked on
      // currentTarget is the element event listener
      // therefore this means clicks on child components are ignored
      if (e.target === e.currentTarget) {
        e.preventDefault()
        e.stopPropagation()
        if (!disableClickToggle) toggle()
      }
    },
    [toggle, disableClickToggle]
  )

  const handleKeyUp = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        e.preventDefault()
        e.stopPropagation()
        toggle()
      }
    },
    [toggle]
  )

  return (
    <>
      {allowModalScrolling && <PreventBodyScrolling />}
      <FullScreen
        zIndex={zIndex}
        allowModalScrolling={allowModalScrolling}
        height={height}
        topOffset={topOffset}
      >
        <FadeInBackground
          key="modal-background"
          backgroundOpacity={backgroundOpacity}
          initial="closed"
          animate="open"
          exit="closed"
        >
          <Alignment
            data-testid={testIds.modalChildrenWrapper}
            ref={focusRef}
            tabIndex="0"
            flexDirection="column"
            onKeyUp={handleKeyUp}
            onClick={handleClick}
            height={height}
            {...props}
          >
            {children}
          </Alignment>
        </FadeInBackground>
      </FullScreen>
    </>
  )
}

ModalBackground.propTypes = {
  toggle: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  focus: PropTypes.bool.isRequired,
  zIndex: PropTypes.number.isRequired,
  backgroundOpacity: PropTypes.number.isRequired,
  disableClickToggle: PropTypes.bool,
  allowModalScrolling: PropTypes.bool,
  topOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

const FullScreen = styled.div`
  width: 100vw;
  position: fixed;

  ${({ height }) => css`
    height: ${height};
  `}

  ${({ allowModalScrolling }) =>
    allowModalScrolling
      ? css`
          overflow-y: scroll;
        `
      : css`
          overflow-y: visible;
        `}

  ${({ topOffset }) => css`
    top: ${topOffset};
  `}

  left: 0;

  ${({ zIndex }) => css`
    z-index: ${zIndex};
  `}
`

FullScreen.displayName = 'FullScreen'

// https://stackoverflow.com/questions/9280258/prevent-body-scrolling-but-allow-overlay-scrolling
const PreventBodyScrolling = () => (
  <div data-testid={testIds.preventBodyScrolling}>
    <GlobalStyle
      style={`body {
            overflow: hidden;
          }`}
    />
  </div>
)

Modal.Header = ModalHeader
Modal.Background = ModalBackground

export default Modal
