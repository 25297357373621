import { IncomingMessage } from 'http'
import arrayHasData from 'lib/arrayHasData'
import cookieNames from 'lib/cookieNames'

const getContentLanguage = (
  cookies: { [key: string]: string },
  req: IncomingMessage
) => {
  const header = req?.headers['ra-content-language']

  const contentLanguage: string = arrayHasData(header)
    ? header[0]
    : (header as string)

  // On the server side the 'ra-content-language' header will be set by the nginx-ingress proxy
  // WHen the <ContentLanguageContext> provider renders it will also set the content language cookie
  return {
    contentLanguage: contentLanguage || 'en',
    contentLanguageCookie: cookies[cookieNames.ra_content_language],
  }
}

export default getContentLanguage
