import { gql } from '@apollo/client'

const PROMOTER_FRAGMENT = gql`
  fragment promoterFragment on Promoter {
    id
    name
    termsAgreement
  }
`

export default PROMOTER_FRAGMENT
