export default {
  desktopMenuBackground: 0.9,
  mobileMenuBackground: 0.1,
  modalBackground: 0.9,
  searchModalBackground: 1,
  loginModalBackground: 1,
  blurHeader: 0.5,
  proModalBackground: 0.25,
  inactiveTicketCard: 0.5,
}
