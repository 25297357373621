import Router from 'next/router'

// Hack to set scrollTop because of this issue:
// - https://github.com/zeit/next.js/issues/1309
// - https://github.com/zeit/next.js/issues/3303

const routes = []

const pushCurrentRouteInfo = () => {
  routes.push({ asPath: Router.asPath, scrollY: window.scrollY })
}

const isBack = () => {
  return (
    routes.length >= 2 && Router.asPath === routes[routes.length - 2].asPath
  )
}

const fixScrollPosition = () => {
  let scrollY = 0

  if (isBack()) {
    routes.pop() // route where we come from
    const targetRoute = routes.pop() // route where we return to
    scrollY = targetRoute.scrollY // scrollY we had before
  }

  const urlParams = new URLSearchParams(window?.location?.search)
  const dontScroll =
    urlParams.get('dontScroll') ||
    urlParams.get('page') ||
    window.location.hash !== ''

  if (!dontScroll) {
    window.requestAnimationFrame(() => window.scrollTo(0, scrollY))
  }
}

const bindFixScrollPosition = () => {
  Router.events.on('routeChangeStart', () => {
    pushCurrentRouteInfo()
  })

  Router.events.on('routeChangeComplete', () => {
    fixScrollPosition()
  })
}

const unbindFixScrollPosition = () => {
  Router.events.off('routeChangeStart', () => {
    pushCurrentRouteInfo()
  })

  Router.events.off('routeChangeComplete', () => {
    fixScrollPosition()
  })
}

export { bindFixScrollPosition, unbindFixScrollPosition }
