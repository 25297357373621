import { useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'
import usePreviousValue from 'hooks/usePreviousValue'
import arrayHasData from 'lib/arrayHasData'
import { useMixpanel } from './MixpanelProvider'

const shouldTrackMixpanel = ({
  event,
  properties,
  previousProperties,
  skip,
}) => {
  if (skip) {
    return false
  }

  return event !== '' && !isEqual(previousProperties, properties)
}

const trackGTM = (trackingCodes = []) => {
  if (window && window.dataLayer) {
    trackingCodes.forEach(({ event, code }) => {
      if (event && code) {
        window.dataLayer.push({ event, tracking_code: code })
      }
    })
  }
}

const useTracking = (
  { gtm = [], mixpanel: { event = '', properties = {}, skip = false } = {} },
  onTrack = () => {}
) => {
  const previousCodes = usePreviousValue(gtm)
  const previousProperties = usePreviousValue(properties)

  // GTM
  useEffect(() => {
    if (arrayHasData(gtm) && !isEqual(previousCodes, gtm)) {
      trackGTM(gtm)
      onTrack()
    }
  }, [previousCodes, gtm, onTrack])

  // Mixpanel
  const { mixpanel, mixpanelLoaded } = useMixpanel()
  const [trackPreventedWhileLoading, setTrackPreventedWhileLoading] =
    useState(false)
  // Track initial event

  useEffect(() => {
    const shouldTrack = shouldTrackMixpanel({
      event,
      properties,
      previousProperties,
      skip,
    })

    if (!mixpanelLoaded) {
      if (shouldTrack) {
        // Record that we were going to track it, so that when the hook re-renders due to
        // mixpanel loading we will track it
        setTrackPreventedWhileLoading(true)
      }
      return
    }

    if (trackPreventedWhileLoading || shouldTrack) {
      // Please do not remove the spread operator, Mixpanel is otherwise mutating
      // the properties object in order to add the Mixpanel token
      // https://github.com/mixpanel/mixpanel-js/issues/276
      mixpanel.track(event, { ...properties })

      onTrack()
      setTrackPreventedWhileLoading(false) // reset this as we've done it
    }
  }, [
    skip,
    event,
    previousProperties,
    properties,
    onTrack,
    mixpanel,
    mixpanelLoaded,
    trackPreventedWhileLoading,
  ])
}

export default useTracking
