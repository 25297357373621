import TRACKING_EVENT from '../tracking-event'

const MUSIC_SECTION = [
  '/music',
  '/podcast',
  '/reviews',
  '/reviews/recommends',
  '/reviews/albums',
  '/reviews/singles',
  '/reviews/tech',
  '/reviews/events',
  '/playlists',
  '/mix-of-the-day',
]
const MAGAZINE_SECTION = [
  '/magazine',
  '/features',
  '/films',
  '/exchange',
  '/news',
]

const getMixpanelTracking = (asPath, trackingProperties = {}) => {
  if (MUSIC_SECTION.includes(asPath)) {
    return {
      event: TRACKING_EVENT.viewMusicSection,
      properties: {
        'Page Path': asPath,
        ...trackingProperties,
      },
    }
  }

  if (MAGAZINE_SECTION.includes(asPath)) {
    return {
      event: TRACKING_EVENT.viewMagazineSection,
      properties: {
        'Page Path': asPath,
        ...trackingProperties,
      },
    }
  }

  if (asPath === '/about' || asPath.includes('/about/')) {
    return {
      event: TRACKING_EVENT.viewAboutPage,
      properties: {
        'Page Path': asPath,
        ...trackingProperties,
      },
    }
  }

  if (asPath === '/pro' || asPath.includes('/pro/')) {
    return {
      event: TRACKING_EVENT.viewProPage,
      properties: {
        'Page Path': asPath,
        ...trackingProperties,
      },
    }
  }

  if (asPath === '/') {
    return {
      event: TRACKING_EVENT.viewHomepage,
      properties: {
        'Page Path': asPath,
        ...trackingProperties,
      },
    }
  }

  if (asPath.includes('/festivals')) {
    return {
      event: TRACKING_EVENT.viewFestivalsHomepage,
      properties: {
        'Page Path': asPath,
        ...trackingProperties,
      },
    }
  }

  if (asPath.includes('/user/following')) {
    return {
      event: TRACKING_EVENT.viewFollowingPage,
      properties: {
        'Page Path': asPath,
        ...trackingProperties,
      },
    }
  }

  if (asPath.includes('/user/inbox')) {
    return {
      event: TRACKING_EVENT.viewInboxPage,
      properties: {
        'Page Path': asPath,
        ...trackingProperties,
      },
    }
  }

  if (asPath.includes('/user/settings/account/#')) {
    return {
      event: TRACKING_EVENT.clickedLogout,
      properties: {
        'Page Path': asPath,
        ...trackingProperties,
      },
    }
  }

  return undefined
}

const trackPageView = (
  asPath,
  { trackGTM = true, mixpanel, trackingProperties } = {}
) => {
  if (trackGTM && window && window.dataLayer) {
    window.dataLayer.push({ event: TRACKING_EVENT.pageView })
  }

  const mixpanelTracking = getMixpanelTracking(asPath, trackingProperties)

  if (mixpanel && mixpanelTracking) {
    mixpanel.track(mixpanelTracking.event, mixpanelTracking.properties)
  }
}

export default trackPageView
