import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

export default {
  facebook: {
    appId: '712773712080127',
  },
  twitter: {
    site: '@residentadvisor',
    cardType: 'summary_large_image',
  },
  openGraph: {
    site_name: 'Resident Advisor',
    images: [
      {
        url: `${publicRuntimeConfig.PUBLIC_ASSETS_BASE_PATH}/images/ra-co-logo.jpeg`,
      },
    ],
  },
}
