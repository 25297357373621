/* eslint-disable no-unused-vars */
import { Component } from 'react'
import PropTypes from 'prop-types'

class SilentErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {}

  render() {
    if (this.state.hasError) {
      return null
    }

    return this.props.children
  }
}

SilentErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SilentErrorBoundary
