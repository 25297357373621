import getConfig from 'next/config'
import axios from 'axios'
import { captureError } from 'lib/logger'

const { publicRuntimeConfig } = getConfig()

const getLanguage = async (locale) => {
  let messages = null

  try {
    const result = await axios(
      `${publicRuntimeConfig.LANGUAGE_FILES_LOCATION}${locale}.json`
    )

    if (result?.data) {
      messages = result.data
    }
  } catch (error) {
    captureError(error)
  }

  return messages
}
export { getLanguage as default }
