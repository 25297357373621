import { createContext, useContext } from 'react'
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes'

const ServerTimeContext = createContext()

export const useServerTime = (options = { rounded: true }) => {
  const serverTime = useContext(ServerTimeContext)

  // Round to nearest minute by default
  // This ensures queries using server time as a variable don't break the cache every time
  if (options.rounded) {
    return roundToNearestMinutes(serverTime).getTime()
  }

  return serverTime
}

export default ServerTimeContext
