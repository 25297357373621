import { Alignment } from '@resident-advisor/design-system'
import Modal from 'components/generic/modal'
import DefaultLoadingSpinner from './DefaultLoadingSpinner'

// Render Modal for full screen loading spinner so it appears on top of page
const FullScreenLoadingSpinner = () => (
  <Modal backgroundOpacity={0} toggle={noop}>
    <Alignment
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <DefaultLoadingSpinner />
    </Alignment>
  </Modal>
)

// Don't want ability to toggle this modal, so pass in toggle function that does nothing
const noop = () => null

export default FullScreenLoadingSpinner
