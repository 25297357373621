import { useEffect, useRef } from 'react'

const useFocus = (shouldFocus = true) => {
  const ref = useRef()

  useEffect(() => {
    if (shouldFocus && ref.current) {
      ref.current.focus()
    }
  }, [ref, shouldFocus])

  return ref
}

export default useFocus
