import Script from 'next/script'
import { useState, createContext, useContext } from 'react'
import getConfig from 'next/config'
import PropTypes from 'prop-types'
import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import featureSwitches from 'enums/feature-switches'
import { useRouter } from 'next/router'

const {
  publicRuntimeConfig: { MIXPANEL_PROJECT_TOKEN, MIXPANEL_PROXY_HOST },
} = getConfig()

const UTM_KEYS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
]

const MixpanelContext = createContext()

if (typeof window !== 'undefined') {
  // Self-hosting the mixpanel-lib instead of using their CDN for reasons outlined here:
  // https://web.dev/optimizing-content-efficiency-loading-third-party-javascript/#use-resource-hints-to-reduce-connection-setup-time
  // Filesnames have been hashed to prevent adblockers blocking based on name
  window.MIXPANEL_CUSTOM_LIB_URL =
    '/lib/156620626cad3b5ef56683e1e2e7cf68346453de.js'
}

const MIXPANEL_ID = '0eafecba2de74fb4848d5df359978c69'

const MixpanelProvider = ({ children }) => {
  const [mixpanel, setMixpanel] = useState(null)

  const enableMixpanelLocalStorage = useFeatureSwitch(
    featureSwitches.enableMixpanelLocalStorage
  )

  const enableMixpanelProxy = useFeatureSwitch(
    featureSwitches.enableMixpanelProxy
  )

  const utmValues = useGetUtmValues()

  const mixpanelConfig = {
    loaded: () => setMixpanel(window.mixpanel),
    persistence: enableMixpanelLocalStorage ? 'localStorage' : 'cookie',
  }

  if (enableMixpanelProxy) {
    mixpanelConfig.api_host = MIXPANEL_PROXY_HOST
  }

  const onLoad = () => {
    window.mixpanel.init(MIXPANEL_PROJECT_TOKEN, mixpanelConfig)
    window.mixpanel.register(utmValues)
  }

  return (
    <MixpanelContext.Provider
      value={{
        mixpanel,
        mixpanelLoaded: mixpanel !== null,
      }}
    >
      <Script
        data-testid={MIXPANEL_ID}
        id={MIXPANEL_ID}
        strategy="afterInteractive"
        src="/lib/8e7b72c622bdcc8f0196ee53e89d0f9e84cc12e0.js" // Filename hashed to prevent adblockers blocking based on name
        onLoad={onLoad}
      />
      {children}
    </MixpanelContext.Provider>
  )
}

const useMixpanel = () => useContext(MixpanelContext)

MixpanelProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useGetUtmValues = () => {
  const { query } = useRouter()

  return UTM_KEYS.reduce((acc, key) => {
    if (query[key]) {
      acc[key] = query[key]
    }
    return acc
  }, {})
}

export { useMixpanel, MIXPANEL_ID, useGetUtmValues }
export default MixpanelProvider
