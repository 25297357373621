import { useEffect, useRef } from 'react'
import {
  useFeatureSwitch,
  useFeatureSwitchVariant,
} from 'context/FeatureSwitchesContext'
import { setCookie } from 'lib/cookieHelpers'
import arrayHasData from 'lib/arrayHasData'
import safelyParseJSON from 'lib/safelyParseJSON'
import cookieNames from 'lib/cookieNames'

import TRACKING_EXPERIMENT from '../tracking-experiment'
import { useMixpanel } from './MixpanelProvider'

/**
 * Gets experiment data from our feature switches server and starts an experiment
 * in Mixpanel.
 *
 * The experiment will only be triggered once per render, even if the feature switch value changes.
 * This is controlled by the `hasTracked` ref, which is reset when the component unmounts.
 *
 * https://help.mixpanel.com/hc/en-us/articles/360038439952-Experiments-Report
 * https://docs.getunleash.io/advanced/toggle_variants
 *
 * @param {string} featureSwitch the feature switch to use for the experiment
 * @param {{ name: string, skip: boolean }} options
 *  - `name`: optional override for the experiment name
 *  - `skip`: indicates whether to skip starting the experiment even if the feature switch is disabled
 * @returns {{ enabled: boolean, variant: string, payload: object}}
 *  - `enabled`: whether the feature switch is enabled
 *  - `variant`: the experiment variant name
 *  - `payload`: the parsed experiment payload value
 */
const useTrackingExperiment = (
  featureSwitch,
  { name = '', skip = false } = {}
) => {
  const enabled = useFeatureSwitch(featureSwitch)
  const variant = useFeatureSwitchVariant(featureSwitch)
  const { mixpanel, mixpanelLoaded } = useMixpanel()
  const payload = safelyParseJSON(variant.payload?.value)

  /*
   Keeping track of whether tracking has already been called so we don't get duplicate experiments.
   Cleanup the "hasTracked" ref when the component unmounts so that the experiment can be tracked again if the component is re-mounted.
  */
  const hasTracked = useRef(false)
  useEffect(() => {
    return () => {
      hasTracked.current = false
    }
  }, [])

  useEffect(() => {
    if (!mixpanelLoaded) {
      return
    }

    const nameToUse = name || TRACKING_EXPERIMENT[featureSwitch]?.name

    if (nameToUse) {
      const currentVariants = mixpanel.get_property(EXPERIMENT_DATA_NAME)

      const newExperiment = `${nameToUse}:${variant.name}`

      const experimentData = arrayHasData(currentVariants)
        ? [
            ...currentVariants.filter(
              (currentVariant) => !currentVariant.includes(nameToUse)
            ),
            newExperiment,
          ]
        : [newExperiment]

      mixpanel.register({
        [EXPERIMENT_DATA_NAME]: experimentData,
      })

      setCookie(
        cookieNames.ra_mp_experimentdata,
        JSON.stringify(experimentData),
        { maxAge: 31536000 } // 1 year in ms
      )

      if (enabled && !skip && !hasTracked.current) {
        mixpanel.track(MIXPANEL_EVENT_NAME, {
          [EXPERIMENT_NAME_PROPERTY]: nameToUse,
          [VARIANT_NAME_PROPERTY]: variant.name,
          ...payload,
        })
        hasTracked.current = true
      }
    }
  }, [
    featureSwitch,
    enabled,
    skip,
    name,
    variant.name,
    payload,
    mixpanelLoaded,
    mixpanel,
  ])

  return {
    enabled,
    variant: variant.name,
    payload,
  }
}

const EXPERIMENT_DATA_NAME = 'ExperimentData'
const EXPERIMENT_NAME_PROPERTY = 'Experiment name'
const VARIANT_NAME_PROPERTY = 'Variant name'
const MIXPANEL_EVENT_NAME = '$experiment_started' // https://help.mixpanel.com/hc/en-us/articles/360038439952-Experiments-Report

export default useTrackingExperiment
