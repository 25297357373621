import { useState, useEffect } from 'react'
import { sizes } from '@resident-advisor/design-system'

const useShowAd = (sizesToShow = {}) => {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (sizesToShow.l && window.innerWidth >= sizes.l) {
      // large screen
      setShow(true)
    } else if (
      sizesToShow.m &&
      window.innerWidth >= sizes.m &&
      window.innerWidth < sizes.l
    ) {
      // medium screen
      setShow(true)
    } else if (
      sizesToShow.s &&
      window.innerWidth >= sizes.s &&
      window.innerWidth < sizes.m
    ) {
      // small screen
      setShow(true)
    }
    setLoading(false)
  }, [sizesToShow])

  return { show, setShow, loading }
}

export default useShowAd
