import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { setCookie } from 'lib/cookieHelpers'
import cookieNames from 'lib/cookieNames'

const useUtmTracking = () => {
  /* eslint-disable camelcase */
  const {
    query: { utm_source, utm_medium, utm_campaign },
  } = useRouter()

  useEffect(() => {
    if (
      utm_source &&
      utm_medium &&
      utm_campaign &&
      typeof utm_source === 'string' &&
      typeof utm_campaign === 'string' &&
      typeof utm_medium === 'string'
    ) {
      const cookie: UtmTrackingCookie = {
        utmSource: utm_source,
        utmMedium: utm_medium,
        utmCampaign: utm_campaign,
      }

      setCookie(cookieNames.utm_tracking, JSON.stringify(cookie))
    }
  }, [utm_source, utm_medium, utm_campaign])
  /* eslint-enable camelcase */
}

type UtmTrackingCookie = {
  utmSource: string
  utmMedium: string
  utmCampaign: string
}

export type { UtmTrackingCookie }
export default useUtmTracking
