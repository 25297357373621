const getLocaleEnvPrefix = (
  separator = '',
  localeEnvironment = process.env.ENVIRONMENT,
  localeEnvPrefix = process.env.LOCALE_DOMAIN_PREFIX
) => {
  if (localeEnvironment !== 'production') {
    return localeEnvPrefix + separator
  }
  return ''
}

module.exports = getLocaleEnvPrefix
