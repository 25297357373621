import PropTypes from 'prop-types'
import DefaultError from 'components/generic/errors/default-error'
import { ApolloError } from '@apollo/client'
import React from 'react'
import DefaultLoadingSpinner from './DefaultLoadingSpinner'
import {
  useQueryResultHandler,
  UseQueryResultHandlerOptions,
} from './useQueryResult'

const QueryResultHandler = ({
  data,
  loading,
  error,
  markup: Markup,
  handleError,
  handleLoading = () => <DefaultLoadingSpinner />,
  handleEmpty = () => null,
  dataKey,
  showDefaultError,
  markupProps = {},
  ...props
}: QueryResultHandlerProps) => {
  const {
    data: innerData,
    loading: innerLoading,
    empty,
    error: innerError,
  } = useQueryResultHandler({
    data,
    loading,
    error,
    dataKey,
  })

  if (innerLoading) return handleLoading()
  if (innerError) {
    if (handleError) {
      return handleError(innerError)
    }
    if (showDefaultError) return <DefaultError />
    return null
  }

  if (empty) return handleEmpty()

  return <Markup data={innerData} {...markupProps} {...props} />
}

QueryResultHandler.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  markup: PropTypes.func.isRequired,
  handleError: PropTypes.func,
  handleEmpty: PropTypes.func,
  handleLoading: PropTypes.func,
  dataKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showDefaultError: PropTypes.bool,
  markupProps: PropTypes.object,
}

interface QueryResultHandlerProps extends UseQueryResultHandlerOptions {
  /** Component to render markup from the returned data */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  markup: React.FC<{ data: any }>
  /** Function to handle query errors */
  handleError?: (error: ApolloError) => JSX.Element
  /** Function to handle rendering during loading state */
  handleLoading?: () => JSX.Element
  /** Function to handle no data being returned for the given `dataKey` */
  handleEmpty?: () => JSX.Element
  /** Whether to show the default error component */
  showDefaultError?: boolean
  /** Extra props to pass to the markup component */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  markupProps?: Record<string, any>
}

export type { QueryResultHandlerProps }
export default QueryResultHandler
