import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useMemo,
} from 'react'
import { getNames, registerLocale } from 'i18n-iso-countries'
import PropTypes from 'prop-types'
import SupportedLocales from 'enums/supported-locales'
import i18nActions from './i18nActions'
import reducer from './i18nReducer'

const I18nContext = createContext()

const I18nProvider = ({ children, locale, messages }) => {
  const initialState = {
    lang: locale,
    locale,
    messages,
    isoCountries: {},
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const contextValue = useMemo(() => {
    return { state, dispatch }
  }, [state, dispatch])

  useEffect(() => {
    // https://github.com/michaelwittig/node-i18n-iso-countries#installing
    const loadCountries = async () => {
      let localeData
      try {
        /* eslint-disable-next-line no-unsanitized/method */
        localeData = await import(
          `i18n-iso-countries/langs/${contextValue.state.lang}.json`
        )
      } catch {
        /* eslint-disable-next-line no-unsanitized/method */
        localeData = await import(
          `i18n-iso-countries/langs/${SupportedLocales.English}.json`
        )
      }
      registerLocale(localeData.default)
      dispatch({
        type: i18nActions.SET_ISO_COUNTRIES,
        value: getNames(contextValue.state.lang),
      })
    }

    loadCountries()
  }, [contextValue.state.lang])

  return (
    <I18nContext.Provider value={contextValue}>{children}</I18nContext.Provider>
  )
}

const useI18nContext = () => useContext(I18nContext)

I18nProvider.propTypes = {
  children: PropTypes.node.isRequired,
  messages: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
}

export { I18nProvider, useI18nContext }
