import PropTypes from 'prop-types'
import { Alignment, variants } from '@resident-advisor/design-system'
import buttonTrackingIds from 'tracking/button-tracking-ids'
import CloseButton from 'components/generic/close-button'

const ModalHeader = ({ toggle, children, ...props }) => (
  <Alignment
    justifyContent={children ? 'space-between' : 'flex-end'}
    alignItems="center"
    pt={{ s: 3, m: 4 }}
    {...props}
  >
    {children}
    <CloseButton
      variant={variants.button.tertiary}
      onClick={toggle}
      data-button-tracking-id={buttonTrackingIds.closeModal}
      iconSize={24}
    />
  </Alignment>
)

ModalHeader.propTypes = {
  toggle: PropTypes.func.isRequired,
  children: PropTypes.node,
}

ModalHeader.defaultProps = {
  children: null,
}

export default ModalHeader
