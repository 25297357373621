import { ApolloLink } from '@apollo/client'
import getTokenHelpers, {
  GetTokenHelpersConfiguration,
} from './getTokenHelpers'
import createTokenRefreshLink from './createTokenRefreshLink'
import createErrorLink from './createErrorLink'
import createHeadersLink, {
  CreateHeadersLinkConfiguration,
} from './createHeadersLink'
import createHttpLink from './createHttpLink'

export type CreateApolloLinkConfiguration = {
  tokenHelpersConfig: GetTokenHelpersConfiguration
  createHeadersLinkConfig: CreateHeadersLinkConfiguration
}

// https://www.apollographql.com/docs/react/api/link/introduction/
const createApolloLink = (config: CreateApolloLinkConfiguration, isBrowser) => {
  const tokenHelpers = getTokenHelpers(config.tokenHelpersConfig)

  const isProduction = process.env.NODE_ENV === 'production'

  const tokenRefreshLink = createTokenRefreshLink(
    tokenHelpers,
    isBrowser,
    isProduction
  )
  const errorLink = createErrorLink()
  const headerLink = createHeadersLink(
    config.createHeadersLinkConfig,
    tokenHelpers.getIdToken,
    isBrowser
  )
  const httpLink = createHttpLink(isBrowser, isProduction)

  // Filter out non-truthy links (at the moment `createTimeoutLink` can return null)
  // Http link is a 'terminating' link so must come last
  // https://www.apollographql.com/docs/react/api/link/introduction/#the-terminating-link
  const links = [tokenRefreshLink, errorLink, headerLink, httpLink].filter(
    (link) => link
  )

  return ApolloLink.from(links)
}

export default createApolloLink
