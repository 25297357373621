import { setContext } from '@apollo/client/link/context'
import isEmpty from 'lodash/isEmpty'
import getCloudflareLocationHeaders from 'lib/getCloudflareLocationHeaders'

export type CreateHeadersLinkConfiguration = {
  contentLanguage?: string
  headers?: Record<string, string>
  remoteAddress?: string
  query?: Record<string, string>
  getServerSideCookies?: (isBrowser: boolean) => string | null
}

const createHeadersLink = (
  args: CreateHeadersLinkConfiguration,
  getIdToken,
  isBrowser
) =>
  setContext((_, { headers }) => ({
    headers: {
      ...headers,
      ...getCustomHeaders(args, getIdToken, isBrowser),
    },
  }))

const getCustomHeaders = (
  args: CreateHeadersLinkConfiguration,
  getIdToken,
  isBrowser
) => {
  let customHeaders: Record<string, unknown> = {
    'RA-Content-Language': args.contentLanguage,
  }

  const idToken = getIdToken()
  if (idToken) {
    customHeaders.authorization = `Bearer ${idToken}`
  }

  const xForwardedFor = args?.headers?.['x-forwarded-for']
  const remoteAddress = args?.remoteAddress
  const xForwardedForHeader = xForwardedFor || remoteAddress
  if (xForwardedForHeader) {
    customHeaders['X-Forwarded-For'] = xForwardedForHeader
  }

  const cloudflareLocationHeaders = getCloudflareLocationHeaders(args?.headers)

  if (!isEmpty(cloudflareLocationHeaders)) {
    customHeaders = {
      ...customHeaders,
      ...cloudflareLocationHeaders,
    }
  }

  const query = args?.query || {}
  const serverSideIsPreview = 'preview' in query
  const clientSideIsPreview =
    isBrowser && window?.location?.search?.includes('preview')

  if (clientSideIsPreview || serverSideIsPreview) {
    customHeaders['Cache-Control'] = 'no-cache'
  }

  const cookies = args?.getServerSideCookies?.(isBrowser)
  if (cookies) {
    customHeaders.Cookie = cookies
  }

  if (!isBrowser) {
    // Add user agent for SSR so that we can differentiate the Apollo client calls from the other NodeFetch calls
    // On the client side use the default browser user agent
    customHeaders['User-Agent'] = 'RANext/1.0 ApolloClientSSR'
  }

  return customHeaders
}

export default createHeadersLink
