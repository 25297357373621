import styled from 'styled-components'
import { Stack } from '@resident-advisor/design-system'
import AdMarkup from './AdMarkup'

// If there are 2+ adjacent ads, only 1 will display...
// Useful for cases like <MusicHome />, where there is not always content
// for some sub-sections - so we can end up with multiple <Ads> in a row
const AdSafeStack = styled(Stack)`
  ${AdMarkup} + ${AdMarkup} {
    display: none;
  }
`

export default AdSafeStack
