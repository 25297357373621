import { TokenRefreshLink } from 'apollo-link-token-refresh'
import getConfig from 'next/config'
import getUriBranch from 'lib/getUriBranch'
import AUTH_API_VERSION from 'enums/auth-api-version'

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig()

const createTokenRefreshLink = (tokenHelpers, isBrowser, isProduction) => {
  const { hasRefreshToken, getIdToken } = tokenHelpers

  const authUri = getUriBranch(
    isBrowser,
    isProduction,
    publicRuntimeConfig.AUTH_SERVICE,
    serverRuntimeConfig.INTERNAL_AUTH_SERVICE
  )

  const fetchAccessToken = () =>
    fetch(`${authUri}/api/${AUTH_API_VERSION.v2}/refresh`, {
      method: 'POST',
      credentials: 'include',
    })

  // Cookies are set using Set-Cookie headers, so post fetch is a no-op
  const handleFetch = () => {}
  const handleError = () => {}

  // Don't attempt to refresh ID token when one exists
  // Or when you don't have a refresh token to get it with
  const isTokenValidOrUndefined = () => !!getIdToken() || !hasRefreshToken()

  return new TokenRefreshLink({
    isTokenValidOrUndefined,
    fetchAccessToken,
    handleFetch,
    handleError,
  })
}

export default createTokenRefreshLink
