import PropTypes from 'prop-types'
import DefaultError from 'components/generic/errors/default-error'
import { useSetStatusCode, STATUS_CODES } from 'components/request-logging'
import { PropsWithChildren } from 'react'
import ErrorType from './error-type'
import SilentErrorBoundary from './SilentErrorBoundary'
import LoggingErrorBoundary from './LoggingErrorBoundary'

const ErrorBoundary = ({
  fallback = () => <DefaultError />,
  silent,
  children,
  errorType,
  additionalContext,
}: PropsWithChildren<ErrorBoundaryProps>) => {
  const setStatusCode = useSetStatusCode()

  // noop if rendering server-side
  if (typeof window === 'undefined') {
    return <>{children}</>
  }

  if (silent) {
    return <SilentErrorBoundary>{children}</SilentErrorBoundary>
  }

  return (
    <LoggingErrorBoundary
      fallback={fallback}
      onError={() => setStatusCode(STATUS_CODES.INTERNAL_SERVER_ERROR)}
      errorType={errorType}
      additionalContext={additionalContext}
    >
      {children}
    </LoggingErrorBoundary>
  )
}

const errorBoundaryPropTypes = {
  fallback: PropTypes.func,
  silent: PropTypes.bool,
  children: PropTypes.node,
  errorType: PropTypes.oneOf(Object.values(ErrorType)),
  additionalContext: PropTypes.object as PropTypes.Requireable<
    Record<string, unknown>
  >,
}

ErrorBoundary.propTypes = errorBoundaryPropTypes

type ErrorBoundaryProps = PropTypes.InferProps<typeof errorBoundaryPropTypes>

export default ErrorBoundary
