import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import usePageHeight from 'hooks/usePageHeight'
import animation from 'themes/animation'

const EaseInFromBottomRightCorner = ({ transitions, children, ...props }) => {
  const pageHeight = usePageHeight()
  return (
    <motion.div
      variants={{
        open: {
          x: '0%',
          y: '0%',
          transition: transitions.open,
        },
        closed: {
          x: '100%',
          y: '100%',
          transition: transitions.closed,
        },
      }}
      {...props}
    >
      <AngleTop viewBox="0 0 50 50">
        <Polygon points="50,50 50,0 0,50" />
      </AngleTop>
      {children}
      <AngleLeft viewBox="0 0 50 50" pageHeight={pageHeight}>
        <Polygon points="50,50 50,0 0,50" />
      </AngleLeft>
    </motion.div>
  )
}

EaseInFromBottomRightCorner.propTypes = {
  transitions: PropTypes.shape({
    open: PropTypes.shape(),
    closed: PropTypes.shape(),
  }),
  children: PropTypes.node.isRequired,
}

EaseInFromBottomRightCorner.defaultProps = {
  transitions: animation.default,
}

const Polygon = styled.polygon`
  ${({ theme }) =>
    css`
      fill: ${theme.mobileModal.backgroundColor};
    `}
`

const AngleTop = styled.svg`
  position: absolute;
  bottom: 100%;
`

const AngleLeft = styled.svg`
  position: absolute;
  top: 0%;

  ${(props) => css`
    left: -${props.pageHeight};
    width: ${props.pageHeight};
    height: ${props.pageHeight};
  `}
`

export default EaseInFromBottomRightCorner
