import { createContext, useReducer, useContext } from 'react'
import PropTypes from 'prop-types'
import { colors } from '@resident-advisor/design-system'

const actions = {
  SET_MENU_COLOR: 'SET_MENU_COLOR',
  SET_HOVER_COLOR: 'SET_HOVER_COLOR',
  SET_GLOBAL_NAV_HEIGHT: 'SET_GLOBAL_NAV_HEIGHT',
  RESET_MENU_COLOR: 'RESET_MENU_COLOR',
  RESET_HOVER_COLOR: 'RESET_HOVER_COLOR',
  OPEN_MENU: 'OPEN_MENU',
  CLOSE_MENU: 'CLOSE_MENU',
  TOGGLE_MOBILE_MENU: 'OPEN_MOBILE_MENU',
  TOGGLE_MOBILE_MENU_USER_SETTINGS: 'OPEN_MOBILE_MENU_USER_SETTINGS',
  SET_INDICATOR_VARIANT: 'SET_INDICATOR_VARIANT',
  RESET_INDICATOR_VARIANT: 'RESET_INDICATOR_VARIANT',
  SET_HAS_BACKGROUND_COLOR: 'SET_HAS_BACKGROUND_COLOR',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_MENU_COLOR:
      return {
        ...state,
        color: action.payload,
      }
    case actions.RESET_MENU_COLOR:
      return { ...state, color: 'primary' }
    case actions.SET_HOVER_COLOR:
      return {
        ...state,
        hoverColor: action.payload,
      }
    case actions.RESET_HOVER_COLOR:
      return { ...state, hoverColor: colors.coral }
    case actions.OPEN_MENU:
      return { ...state, showMenu: true }
    case actions.CLOSE_MENU:
      return { ...state, showMenu: false }
    case actions.TOGGLE_MOBILE_MENU:
      return {
        ...state,
        showMobileMenu: !state.showMobileMenu,
        showMobileMenuUserSettings: !state.showMobileMenu
          ? false
          : state.showMobileMenuUserSettings,
      }
    case actions.TOGGLE_MOBILE_MENU_USER_SETTINGS:
      return {
        ...state,
        showMobileMenuUserSettings: !state.showMobileMenuUserSettings,
      }
    case actions.SET_INDICATOR_VARIANT:
      return { ...state, variant: action.payload }
    case actions.RESET_INDICATOR_VARIANT:
      return { ...state, variant: 'primary' }
    case actions.SET_HAS_BACKGROUND_COLOR:
      return { ...state, hasBackgroundColor: action.payload }
    case actions.SET_GLOBAL_NAV_HEIGHT:
      return { ...state, globalNavHeight: action.payload }
    default:
      return state
  }
}

const MenuContext = createContext([])

const MenuContextProvider = ({ children, initialState }) => {
  const innerInitialState = {
    color: 'primary',
    hoverColor: colors.coral,
    showMenu: false,
    showMobileMenu: false,
    showMobileMenuUserSettings: false,
    globalNavHeight: undefined,
    ...initialState,
  }

  const [state, dispatch] = useReducer(reducer, innerInitialState)

  return (
    <MenuContext.Provider value={[state, dispatch]}>
      {children}
    </MenuContext.Provider>
  )
}

MenuContextProvider.propTypes = {
  children: PropTypes.node,
  initialState: PropTypes.shape({}),
}

const useMenuContext = () => useContext(MenuContext)

export { MenuContext, MenuContextProvider, actions, reducer, useMenuContext }
