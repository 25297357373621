import { isValid } from 'date-fns'
import isString from 'lodash/isString'
import isNumber from 'lodash/isNumber'

export const titleCase = (string) => {
  if (!string) {
    return null
  }

  return string
    .split()
    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join()
}

export const getCountryFilter = (area, country) => {
  let countryFilter = null
  if (area?.name?.toLowerCase() !== 'all' && country?.urlCode) {
    countryFilter = country.urlCode.toLowerCase()
  }
  return countryFilter
}

export const getAreaSafeName = (area, country) => {
  if (!area || !country) {
    return ''
  }

  return area.name?.toLowerCase() === 'all' ? country.name : area.name
}

export const getAreaAndCountry = (area) =>
  area.name.toLowerCase() === 'all'
    ? area.country.name
    : `${area.name}, ${area.country.name}`

export const generateUrlForAreaAndCountry = (route, area) => {
  if (!area?.country?.urlCode || !area?.name || !area?.urlName) return route

  return area.name?.toLowerCase() === 'all'
    ? `${route}/${area.country.urlCode.toLowerCase()}/all`
    : `${route}/${area.country.urlCode.toLowerCase()}/${area.urlName.toLowerCase()}`
}

export const getDateFromYear = (year) => {
  if (isString(year) || isNumber(year)) {
    const date = new Date(year, 0)

    if (isValid(date)) return date
  }

  return null
}

export const getRelativeUri = (path) => path?.split('?')[0] || ''
