/* eslint-disable no-restricted-imports */
import {
  setCookie as setNookieCookie,
  destroyCookie as destroyNookieCookie,
} from 'nookies'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const setCookie = (
  cookieName: string,
  value: string,
  options: Parameters<typeof setNookieCookie>[3] & {
    ctx?: Parameters<typeof setNookieCookie>[0]
  } = {}
) => {
  const { ctx, ...cookieOptions } = options

  return setNookieCookie(ctx ?? null, cookieName, value, {
    path: '/',
    // This will set the cookie domain to '.ra.co', meaning cookies
    // will be shared across locale subdomains (e.g. a cookie set
    // on ra.co will be shared with es.ra.co).
    domain: new URL(publicRuntimeConfig.NEXT_WEB_ADDRESS).host,
    ...cookieOptions,
  })
}

const destroyCookie = (cookieName: string) =>
  destroyNookieCookie(null, cookieName, {
    path: '/',
    // This sets the destroy cookie domain to '.ra.co', meaning
    // cookies will be removed across locale subdomains (e.g. a cookie
    // destroyed on ra.co will also be removed on es.ra.co).
    domain: new URL(publicRuntimeConfig.NEXT_WEB_ADDRESS).host,
  })

export { setCookie, destroyCookie }
