import { IVariant, useFlag, useVariant } from '@unleash/proxy-client-react'
import safelyParseJSON from 'lib/safelyParseJSON'
import { useMemo } from 'react'

const useFeatureSwitchData = <T>(
  featureSwitch
): { enabled: boolean; variant: Partial<IVariant>; payload: T } => {
  const enabled = useFlag(featureSwitch)
  const variant = useVariant(featureSwitch)
  const variantPayloadValue = variant.payload?.value

  const payload = useMemo(
    () => safelyParseJSON(variantPayloadValue),
    [variantPayloadValue]
  )

  return {
    enabled,
    variant,
    payload,
  }
}

export default useFeatureSwitchData
