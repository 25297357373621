const safelyParseJSON = (json: string) => {
  if (!json) return {}
  try {
    return JSON.parse(json)
  } catch {
    return {}
  }
}

export default safelyParseJSON
