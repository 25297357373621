import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult as ApolloQueryResult,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

const formatData = <TData>(data: TData, dataKey: string | string[]): TData => {
  if (Array.isArray(dataKey)) {
    return dataKey.reduce((acc, key) => {
      const value = get(data, key)
      if (value) {
        return { ...acc, [key]: value }
      }

      return acc
    }, {} as TData)
  }

  return get(data, dataKey)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useQueryResultHandler = <TData = any>({
  loading,
  error,
  data,
  dataKey,
  previousData,
  startPolling,
  stopPolling,
}: UseQueryResultHandlerOptions<TData>): UseQueryResultHandlerResult<TData> => {
  const formattedPreviousData = formatData(previousData, dataKey)

  if (loading || error) {
    return {
      data: undefined,
      empty: false,
      loading,
      error,
      previousData: formattedPreviousData,
      startPolling,
      stopPolling,
    }
  }

  const formattedData = formatData(data, dataKey)

  if (Array.isArray(dataKey)) {
    if (
      isEmpty(formattedData) ||
      Object.entries(formattedData).every(([, value]) => isEmpty(value))
    ) {
      return {
        data: undefined,
        empty: true,
        loading,
        error,
        previousData: formattedPreviousData,
        startPolling,
        stopPolling,
      }
    }

    return {
      data: formattedData,
      empty: false,
      loading,
      error,
      previousData: formattedPreviousData,
      startPolling,
      stopPolling,
    }
  }

  if (!formattedData || isEmpty(formattedData)) {
    return {
      data: undefined,
      empty: true,
      loading,
      error,
      previousData: formattedPreviousData,
      startPolling,
      stopPolling,
    }
  }

  return {
    data: formattedData,
    empty: false,
    loading,
    error,
    previousData: formattedPreviousData,
    startPolling,
    stopPolling,
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useQueryResult = <TData = any>(
  query: DocumentNode | TypedDocumentNode<unknown, OperationVariables>,
  { dataKey, ...options }: UseQueryResultOptions<TData>
): UseQueryResultHandlerResult<TData> => {
  const { data, loading, error, previousData, startPolling, stopPolling } =
    useQuery<TData>(query, options)

  return useQueryResultHandler<TData>({
    data,
    loading,
    error,
    dataKey,
    previousData,
    startPolling,
    stopPolling,
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface UseQueryResultOptions<TData = any> extends QueryHookOptions<TData> {
  /** The key or keys used to access the desired value in the returned query data */
  dataKey: string | string[]
}

type QueryResult<TData> = Pick<
  ApolloQueryResult<TData>,
  'loading' | 'data' | 'error' | 'previousData'
> & {
  startPolling?: (pollInterval: number) => void
  stopPolling?: () => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface UseQueryResultHandlerOptions<TData = any> extends QueryResult<TData> {
  /** The key or keys used to access the desired value in the returned query data */
  dataKey: string | string[]
}

interface UseQueryResultHandlerResult<TData> extends QueryResult<TData> {
  /** Whether or not the data accessed by the `dataKey` is empty, or doesn't exist */
  empty?: boolean
}

export type {
  UseQueryResultOptions,
  UseQueryResultHandlerOptions,
  UseQueryResultHandlerResult,
}
export { useQueryResult, useQueryResultHandler }
