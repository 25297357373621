import { v4 as uuidv4 } from 'uuid'
import { parseCookies } from 'nookies'
import cookieNames from 'lib/cookieNames'
import { setCookie } from 'lib/cookieHelpers'

const setIterableCookie = () => {
  const distinctId = uuidv4()

  setCookie(cookieNames.iterable, JSON.stringify({ distinctId }))
}

const getIterableDistinctId = () => {
  const iterableCookie = parseCookies()?.[cookieNames.iterable]

  const distinctId = JSON.parse(iterableCookie || '{}')?.distinctId

  return distinctId
}

export { setIterableCookie, getIterableDistinctId }
