import getConfig from 'next/config'
import Script from 'next/script'

const { publicRuntimeConfig } = getConfig()

const RavelinScript = () => (
  <Script
    src="/lib/ravelin-1.3.0-core+track.min.js"
    strategy="lazyOnload"
    onLoad={() => {
      // eslint-disable-next-line no-new, no-undef
      new Ravelin({
        key: publicRuntimeConfig.RAVELIN_PUBLIC_API_KEY,
        cookieDomain: new URL(publicRuntimeConfig.NEXT_WEB_ADDRESS).host,
        syncMs: parseInt(publicRuntimeConfig.RAVELIN_SYNC_MS, 10),
      })
    }}
  />
)

export default RavelinScript
