/* eslint-disable no-undef */
import getConfig from 'next/config'
import Script from 'next/script'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

const { publicRuntimeConfig } = getConfig()

const RivetedScript = () => {
  const router = useRouter()
  const [rivetedLoaded, setRivetedLoaded] = useState(false)

  useEffect(() => {
    if (!rivetedLoaded) {
      return () => {}
    }

    const resetRiveted = () => riveted.reset()
    router.events.on('routeChangeComplete', resetRiveted)

    return () => {
      router.events.off('routeChangeComplete', resetRiveted)
    }
  }, [rivetedLoaded, router])

  return (
    <Script
      src="/lib/riveted.js"
      strategy="lazyOnload"
      onLoad={() => {
        riveted.init({
          reportInterval: publicRuntimeConfig.RIVETED_REPORT_INTERVAL_SECONDS,
        })
        setRivetedLoaded(true)
      }}
    />
  )
}
export default RivetedScript
