import { ApolloClient } from '@apollo/client'
import createApolloLink, { CreateApolloLinkConfiguration } from './link'
import createApolloCache from './createApolloCache'

const createApolloClient = (
  initialState,
  config: CreateApolloLinkConfiguration
) => {
  const isBrowser = typeof window !== 'undefined'

  return new ApolloClient({
    ssrMode: !isBrowser,
    link: createApolloLink(config, isBrowser),
    cache: createApolloCache(initialState),
  })
}

export default createApolloClient
