import useTracking from './tracking-utils/useTracking'
import {
  identify,
  resetIdentity,
  identifyGuest,
} from './tracking-utils/identify'
import trackPageView from './tracking-utils/trackPageView'
import useTrackingExperiment from './tracking-utils/useTrackingExperiment'

class Tracking {
  static trackMixpanel(event, properties = {}) {
    // Mixpanel is loaded very early on, but this guard is to protect against any
    // race conditions
    if (typeof window !== 'undefined' && window.mixpanel) {
      window.mixpanel.track(event, properties)
    }
  }

  static trackPageView = trackPageView

  static useTracking = useTracking

  static identify = identify

  static identifyGuest = identifyGuest

  static resetIdentity = resetIdentity

  static useTrackingExperiment = useTrackingExperiment

  static pushToDataLayer(event) {
    if (window && window.dataLayer) {
      window.dataLayer.push(event)
    }
  }

  static disable() {
    // Mixpanel is loaded very early on, but this guard is to protect against any
    // race conditions
    if (typeof window !== 'undefined' && window.mixpanel) {
      window.mixpanel.disable()
    }
  }
}

export default Tracking
