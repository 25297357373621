import { gql } from '@apollo/client'

const GET_HELPSCOUT_CONFIGS = gql`
  query GET_HELPSCOUT_CONFIGS {
    helpScoutConfigs {
      screen
      url
      helpScoutBeacon {
        beaconId
        name
        customFields {
          fieldId
        }
      }
    }
  }
`

export default GET_HELPSCOUT_CONFIGS
