import i18nActions from './i18nActions'

const reducer = (state, action) => {
  switch (action.type) {
    case i18nActions.SET_LOCALE:
      return {
        ...state,
        locale: action.value,
        lang: action.value,
      }
    case i18nActions.SET_MESSAGES:
      return { ...state, messages: action.value }
    case i18nActions.SET_ISO_COUNTRIES:
      return { ...state, isoCountries: action.value }
    default:
      return state
  }
}

export default reducer
