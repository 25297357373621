import { useQuery } from '@apollo/client'
import { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { useUserContext } from 'context/UserContext'
import GET_AREA from 'queries/GetAreaQuery'
import {
  GET_BOTH_AREAS,
  GET_EVENTS_AREA,
} from './GetPersonalizationAreaQueries'

const PersonalizationContext = createContext()

const usePersonalizationContext = () => useContext(PersonalizationContext)

const PersonalizationProvider = ({ children, areaId, eventsAreaId }) => {
  const {
    loading: userLoading,
    error: userError,
    area: userArea,
  } = useUserContext()

  const { query, variables } = getQueryAndVariables(areaId, eventsAreaId)

  const {
    loading: areasLoading,
    error: areasError,
    data: areasData,
  } = useQuery(query, {
    variables,
    skip: !areaId && !eventsAreaId,
  })

  return (
    <PersonalizationContext.Provider
      value={{
        loading: areasLoading || userLoading,
        error: areasError || userError,
        area: areasData?.area || userArea,
        eventsArea: areasData?.eventsArea,
      }}
    >
      {children}
    </PersonalizationContext.Provider>
  )
}

const getQueryAndVariables = (areaId, eventsAreaId) => {
  if (areaId && eventsAreaId) {
    return {
      query: GET_BOTH_AREAS,
      variables: { areaId, eventsAreaId },
    }
  }
  if (eventsAreaId) {
    return {
      query: GET_EVENTS_AREA,
      variables: { id: eventsAreaId },
    }
  }
  return {
    query: GET_AREA,
    variables: { id: areaId },
  }
}

PersonalizationProvider.propTypes = {
  children: PropTypes.node,
  areaId: PropTypes.string,
  eventsAreaId: PropTypes.string,
}

export { PersonalizationProvider, usePersonalizationContext }
