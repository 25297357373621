const noIndexPaths = [
  '/about',
  '/pro',
  '/features',
  '/news',
  '/reviews',
  '/music',
  '/films',
  '/exchange',
  '/podcast',
]

const indexLocales = ['en']

export { indexLocales, noIndexPaths }
