import React from 'react'
import SupportedLocales from 'enums/supported-locales'
import getLocaleEnvPrefix from 'lib/getLocaleEnvPrefix'
import { useRouter } from 'next/router'
import Head from 'next/head'
import getConfig from 'next/config'

const HeadDomainUrls = () => {
  const { publicRuntimeConfig } = getConfig()
  const { asPath } = useRouter()
  const localePrefix = getLocaleEnvPrefix(
    '-',
    publicRuntimeConfig.ENVIRONMENT,
    publicRuntimeConfig.LOCALE_DOMAIN_PREFIX
  )

  return (
    <Head>
      {Object.entries(SupportedLocales)
        .filter(([, value]) => value !== SupportedLocales.English)
        .map(([key, value]) => {
          const lowercaseKey = key.toLowerCase()
          const lowercaseValue = value.toLowerCase()
          const urlEnvPrefix = `${localePrefix}${lowercaseValue}.`
          return (
            <link
              key={lowercaseKey}
              rel="alternate"
              hrefLang={lowercaseValue as string}
              href={`https://${urlEnvPrefix}ra.co${asPath}`}
              data-testid={`alternate-link-${lowercaseKey}`}
            />
          )
        })}
      <link
        rel="alternate"
        hrefLang="x-default"
        href={`https://${localePrefix}ra.co${asPath}`}
        data-testid="default-link"
      />
    </Head>
  )
}

export default HeadDomainUrls
