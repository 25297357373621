import { Error } from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import messages from 'messages/errors'

const DefaultError = () => {
  const intl = useIntl()
  return <Error message={intl.formatMessage(messages.somethingWentWrong)} />
}

export default DefaultError
