const TRACKING_PROPERTY = {
  loginMethod: {
    email: 'E-mail',
    username: 'Username',
    apple: 'Apple',
    unknown: 'Unknown',
    checkoutRegistration: 'Checkout Registration',
  },
  loginSource: {
    unknown: 'Unknown',
    myAccount: 'My Account',
    login: 'Login',
    checkoutFlow: 'Checkout Flow',
    follow: 'Follow',
    raPro: 'RA Pro',
    submitEvent: 'Submit Event',
    updateEvent: 'Update Event',
    interested: 'Interested in Event',
    resetPassword: 'Reset Password',
    verifyEmail: 'Verify Email',
    pitch: 'Pitch',
    register: 'Register',
    oauthRegister: 'OAuthRegister',
    subscribeToTicketNotifications: 'Subscribe to ticket notifications',
    savedEvents: 'Save an event',
  },
}

export default TRACKING_PROPERTY
