// Values are pulled from the docs
// https://developer.helpscout.com/beacon-2/web/javascript-api/
const BEACON_ACTION = {
  init: 'init',
  destroy: 'destroy',
  open: 'open',
  close: 'close',
  toggle: 'toggle',
  search: 'search',
  suggest: 'suggest',
  article: 'article',
  navigate: 'navigate',
  identify: 'identify',
  prefill: 'prefill',
  reset: 'reset',
  logout: 'logout',
  config: 'config',
  on: 'on',
  off: 'off',
  once: 'once',
  event: 'event',
  sessionData: 'session-data',
  showMessage: 'show-message',
  info: 'info',
  ready: 'ready',
}

export default BEACON_ACTION
