const cookieNames = {
  events_area_id: 'events_area_id',
  area_id: 'area_id',
  spotify: 'spotify',
  soundcloud: 'soundcloud',
  affiliate: 'affiliate',
  locale: 'locale',
  legacy: 'ASP.NET_SessionId',
  ra_content_language: 'ra_content_language',
  displayNav: 'ra-display-nav',
  iterable: 'iterable',
  iterable_in_browser_messaging: 'iterable_in_browser_messaging',
  utm_tracking: 'utm_tracking',
  sessionId: 'sid',
  successfulLoginDuringSession: 'successful-login-during-session',
  cookieConsentAccepted: 'cookie-consent-accepted',
  ra_mp_userid: 'ra_mp_userid', // Matches with ra-web (see _TrackingScript.cshtml)
  ra_mp_experimentdata: 'ra_mp_experimentdata',
  oauth_login_successful: 'oauth_login_successful',
  source_page_refresh_id: 'source_page_refresh_id',
}

module.exports = cookieNames
