import PropTypes from 'prop-types'
import InternalServerError from 'components/error-pages/500'
import ErrorBoundary from './ErrorBoundary'

const PageErrorBoundary = ({ children }) => (
  <ErrorBoundary severity="major" fallback={() => <InternalServerError />}>
    {children}
  </ErrorBoundary>
)

PageErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageErrorBoundary
