const EASING = {
  LINEAR: 'linear',
  EASE_IN: 'easeIn',
  EASE_OUT: 'easeOut',
  EASE_IN_OUT: 'easeInOut',
  CIRC_IN: 'circIn',
  CIRC_OUT: 'circOut',
  CIRC_IN_OUT: 'circInOut',
  BACK_IN: 'backIn',
  BACK_OUT: 'backOut',
  BACK_IN_OUT: 'backInOut',
  ANTICIPATE: 'anticipate',
}

const defaultDuration = 0.25
const defaultStaggerChildren = 0.05

export default {
  default: {
    duration: defaultDuration,
    open: {
      duration: defaultDuration,
      ease: EASING.EASE_OUT,
      staggerChildren: defaultStaggerChildren,
    },
    closed: {
      duration: defaultDuration,
      ease: EASING.EASE_IN,
      staggerChildren: defaultStaggerChildren,
    },
  },
  mobileNavItems: {
    easing: EASING.EASE_OUT,
  },
  slashAnimation: {
    duration: defaultDuration,
    ease: EASING.LINEAR,
    staggerChildren: defaultStaggerChildren,
  },
}
