import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useUserContext } from 'context/UserContext'
import { useRouter } from 'next/router'
import usePreviousValue from 'hooks/usePreviousValue'
import { useOAuthLoginTracking } from 'scripts/tracking/hooks'
import useNavVersion from 'hooks/useNavVersion'
import { useMixpanel } from './MixpanelProvider'

import Tracking from '../Tracking'
import IterableScript from './IterableScript'
import useUtmTracking from './useUtmTracking'

const TrackingWrapper = ({ children }) => {
  const { user, area, loading, isBot, isAuthenticated, closestArea } =
    useUserContext()
  const { asPath } = useRouter()
  const navVersion = useNavVersion()
  const previousAsPath = usePreviousValue(asPath)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [iterableLoading, setIterableLoading] = useState(true)
  const { mixpanel, mixpanelLoaded } = useMixpanel()

  useUtmTracking()

  useEffect(() => {
    if (!mixpanelLoaded || iterableLoading || loading || isBot) {
      return
    }

    if (!isAuthenticated) {
      Tracking.identifyGuest(area.name, mixpanel)
    }

    if (user?.id) {
      const {
        id,
        firstName,
        lastName,
        username,
        email,
        locale,
        dateRegistered,
        registrationSource,
        dateOfBirth,
        managedArtists,
        managedPromoters,
        followedProfilesCount,
      } = user

      Tracking.identify(
        id,
        {
          firstName,
          lastName,
          username,
          email,
          locale,
          dateRegistered,
          area,
          closestArea,
          registrationSource,
          dateOfBirth,
          artistIds: managedArtists?.map((x) => x.id.toString()),
          promoterIds: managedPromoters?.map((x) => x.id.toString()),
          followedProfilesCount,
        },
        mixpanel
      )
    }
  }, [
    iterableLoading,
    user,
    area,
    closestArea,
    loading,
    isBot,
    isAuthenticated,
    mixpanel,
    mixpanelLoaded,
  ])

  useEffect(() => {
    if (!mixpanelLoaded) {
      return
    }

    if (isBot) {
      // Disable all tracking for bots
      Tracking.disable()
    } else if (isInitialLoad) {
      // Track initial load, and don't track in GTM as this is handled by the GTM script
      Tracking.trackPageView(asPath, {
        trackGTM: false,
        mixpanel,
        trackingProperties: { 'Nav Version': navVersion },
      })
      setIsInitialLoad(false)
    } else if (asPath !== previousAsPath) {
      // Track route changes in Mixpanel and GTM
      Tracking.trackPageView(asPath, {
        trackGTM: true,
        mixpanel,
        trackingProperties: { 'Nav Version': navVersion },
      })
    }
  }, [
    isBot,
    asPath,
    isInitialLoad,
    previousAsPath,
    mixpanel,
    mixpanelLoaded,
    navVersion,
  ])

  useOAuthLoginTracking()

  return (
    <>
      {children}
      <IterableScript onLoad={() => setIterableLoading(false)} />
    </>
  )
}

TrackingWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TrackingWrapper
