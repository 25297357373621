import type { IncomingHttpHeaders } from 'http'

/**
 * These headers are automatically attached to all requests by CloudFlare
 * See https://developers.cloudflare.com/rules/transform/managed-transforms/reference/#add-visitor-location-headers
 */
enum CloudflareLocationHeaders {
  Longitude = 'cf-iplongitude',
  Latitude = 'cf-iplatitude',
  Country = 'cf-ipcountry',
  TimeZone = 'cf-timezone',
}

/**
 * If we are not in production, we can provide custom 'override' headers
 * for testing purposes
 *
 * These will be attached to API requests then we can consume them
 * in upstream services to simulate site behaviour in different locations
 */
enum OverrideCloudflareLocationHeaders {
  Longitude = 'override-cf-iplongitude',
  Latitude = 'override-cf-iplatitude',
  TimeZone = 'override-cf-timezone',
}

type CloudflareLocationHeadersResult = {
  [CloudflareLocationHeaders.Longitude]?: string
  [CloudflareLocationHeaders.Latitude]?: string
  [CloudflareLocationHeaders.Country]?: string
  [CloudflareLocationHeaders.TimeZone]?: string
  [OverrideCloudflareLocationHeaders.Longitude]?: string
  [OverrideCloudflareLocationHeaders.Latitude]?: string
  [OverrideCloudflareLocationHeaders.TimeZone]?: string
}

const getCloudflareLocationHeaders = (
  headers?: Record<string, string> | IncomingHttpHeaders
): CloudflareLocationHeadersResult => {
  let result = {}

  if (!headers) {
    return result
  }

  const longitude = headers[CloudflareLocationHeaders.Longitude]
  const latitude = headers[CloudflareLocationHeaders.Latitude]
  const country = headers[CloudflareLocationHeaders.Country]
  const timeZone = headers[CloudflareLocationHeaders.TimeZone]

  if (longitude && latitude) {
    result = {
      [CloudflareLocationHeaders.Longitude]: longitude,
      [CloudflareLocationHeaders.Latitude]: latitude,
      [CloudflareLocationHeaders.Country]: country,
      [CloudflareLocationHeaders.TimeZone]: timeZone,
    }
  }

  if (process.env.ENVIRONMENT !== 'production') {
    const overrideLongitude =
      headers[OverrideCloudflareLocationHeaders.Longitude]
    const overrideLatitude = headers[OverrideCloudflareLocationHeaders.Latitude]
    const overrideTimeZone = headers[OverrideCloudflareLocationHeaders.TimeZone]

    if (overrideLongitude && overrideLatitude) {
      result = {
        ...result,
        [OverrideCloudflareLocationHeaders.Longitude]: overrideLongitude,
        [OverrideCloudflareLocationHeaders.Latitude]: overrideLatitude,
      }
    }

    if (overrideTimeZone) {
      result = {
        ...result,
        [OverrideCloudflareLocationHeaders.TimeZone]: overrideTimeZone,
      }
    }
  }

  return result
}

export { CloudflareLocationHeaders, OverrideCloudflareLocationHeaders }
export default getCloudflareLocationHeaders
