import PropTypes from 'prop-types'

// Not using createGlobalStyle as it causes a memory leak: https://github.com/styled-components/styled-components/issues/3022
const GlobalStyle = ({ style }) => (
  <style
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      // eslint-disable-next-line xss/no-mixed-html
      __html: style,
    }}
  />
)

GlobalStyle.propTypes = {
  style: PropTypes.string.isRequired,
}

export default GlobalStyle
