const {
  captureError,
  logger,
  serverLogger,
  clientSideRequestLogger,
} = require('./logger')

module.exports = {
  captureError,
  logger,
  serverLogger,
  clientSideRequestLogger,
}
