import { useEffect } from 'react'
import Router from 'next/router'

const useRouterRouteChange = (fn) => {
  useEffect(() => {
    Router.events.on('routeChangeStart', fn)

    return () => {
      Router.events.off('routeChangeStart', fn)
    }
  }, [fn])
}

export default useRouterRouteChange
