const featureSwitches = Object.freeze({
  testFeature: 'testFeature', // for unit testing
  enableFourthLinkWithPayloadInGlobalNav:
    'enableFourthLinkWithPayloadInGlobalNav',
  enableRA2122: 'enableRA2122',
  enableSecretVenuesByEventId: 'enableSecretVenuesByEventId',
  promoterTicketFeeTypeExperiment: 'promoterTicketFeeTypeExperiment',
  enableSelectors: 'enableSelectors',
  usePersistedQueries: 'usePersistedQueries',
  enableRealUserMonitoring: 'enableRealUserMonitoring',
  showBankingReminder: 'showBankingReminder',
  cannotRemoveInvoicePromoter: 'cannotRemoveInvoicePromoter',
  hideIPSExperiment: 'hideIPSExperiment',
  hideInterestedCount: 'hideInterestedCount',
  enableRABumps: 'enableRABumps',
  enableEditorialScrollTracking: 'enableEditorialScrollTracking',
  useUpdatedEventListingsComponent: 'useUpdatedEventListingsComponent',
  enableRAPicksEventPage: 'enableRAPicksEventPage',
  enableMixpanelLocalStorage: 'enableMixpanelLocalStorage',
  enableTikTokPixelTracking: 'enableTikTokPixelTracking',
  enableRaProRestructure: 'enableRaProRestructure',
  enableSelectorsSavedArtistLists: 'enableSelectorsSavedArtistLists',
  showEventInterest: 'showEventInterest',
  enablePermissionsView: 'enablePermissionsView',
  enableMixpanelProxy: 'enableMixpanelProxy',
  enableBumpsPage: 'enableBumpsPage',
  enableRaBumpsAd: 'enableRaBumpsAd',
  enableNewMainNav: 'enableNewMainNav',
  enableFacetedSearchNews: 'enableFacetedSearchNews',
  entire_enablePurchaseFlow: 'entire_enablePurchaseFlow',
  enableBumpsApi: 'enableBumpsApi',
  disableCustomCookieScript: 'disableCustomCookieScript',
  enableUpdateBumps: 'enableUpdateBumps',
  enableSavedEvents: 'enableSavedEvents',
  enableEventListingsScoreSandbox: 'enableEventListingsScoreSandbox',
  enablePresalePage: 'enablePresalePage',
  enableNewAutoLocateOutsideCities: 'enableNewAutoLocateOutsideCities',
  enableLocationFiltering: 'enableLocationFiltering',
  enableNewThirdPartyAffiliateCookie: 'enableNewThirdPartyAffiliateCookie',
  enableTicketStatisticsEmbeddableDashboard:
    'enableTicketStatisticsEmbeddableDashboard',
  enableEventsWidget: 'enableEventsWidget',
  entire_enableScannerPins: 'entire_enableScannerPins',
  enableEventsWidgetPage: 'enableEventsWidgetPage',
  enableBumpsInWeb: 'enableBumpsInWeb',
  enablePopularBumpsInWeb: 'enablePopularBumpsInWeb',
  enableNewEventOverviewPage: 'enableNewEventOverviewPage',
  enableNewTagVisibility: 'enableNewTagVisibility',
  enablePerformanceTracking: 'enablePerformanceTracking',
  enableMultiStageForDimensionsRegional:
    'enableMultiStageForDimensionsRegional',
  enableEventListingsWithBumpsQuery: 'enableEventListingsWithBumpsQuery',
})

module.exports = featureSwitches
