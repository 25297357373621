import { useRouter } from 'next/router'
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
} from 'react'

type PreviousPathContextState = {
  previousPath: string | null
}

const PreviousPathContext = createContext<PreviousPathContextState>({
  previousPath: null,
})

const usePreviousPathState = (): PreviousPathContextState => {
  const { asPath } = useRouter()

  const ref = useRef<string | null>(null)

  useEffect(() => {
    ref.current = asPath
  }, [asPath])

  return { previousPath: ref.current }
}

const PreviousPathContextProvider = ({
  children,
}: PropsWithChildren<object>) => {
  const previousPathState = usePreviousPathState()

  return (
    <PreviousPathContext.Provider value={previousPathState}>
      {children}
    </PreviousPathContext.Provider>
  )
}

const usePreviousPathContext = (): PreviousPathContextState =>
  useContext(PreviousPathContext)

export { usePreviousPathContext }
export default PreviousPathContextProvider
