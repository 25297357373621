import cookieNames from 'lib/cookieNames'
import { parseCookies } from 'nookies'
import { useState } from 'react'
import { destroyCookie } from 'lib/cookieHelpers'
import Tracking from '../Tracking'
import TRACKING_EVENT from '../tracking-event'

// When users login via OAuth they are redirected to our /oauth/apple/callback handler, and from
// there they will be redirected to RA-Next
// This hook is used to track in Mixpanel the login attempt completing successfully, as it will
// not be captured by the usual client side logging tracking
const useOAuthLoginTracking = () => {
  const oauthLoginSuccessfulCookie =
    parseCookies()?.[cookieNames.oauth_login_successful]

  const parsed = parseOauthLoginSuccessfulCookie(oauthLoginSuccessfulCookie)
  const [track, setTrack] = useState(!!parsed)

  const onTrack = () => {
    setTrack(false)
    destroyCookie(cookieNames.oauth_login_successful)
  }

  Tracking.useTracking(
    {
      mixpanel: {
        event: TRACKING_EVENT.loginSuccess,
        properties: {
          'Login Method': parsed?.loginMethod,
          'Login Source': parsed?.loginSource,
        },
        skip: !track,
      },
    },
    onTrack
  )
}

const parseOauthLoginSuccessfulCookie = (
  cookie: string
): { loginMethod: string; loginSource: string } => {
  if (!cookie) {
    return null
  }

  try {
    const parsed = JSON.parse(cookie)
    if (
      !parsed ||
      typeof parsed !== 'object' ||
      !parsed.loginMethod ||
      !parsed.loginSource
    ) {
      return null
    }

    return parsed
  } catch (e) {
    return null
  }
}

export default useOAuthLoginTracking
