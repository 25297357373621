import { logger } from 'lib/logger'
import { parseCookies } from 'nookies'
import getConfig from 'next/config'
import getContentLanguage from 'lib/getContentLanguage'
import { CreateApolloLinkConfiguration } from './index'
import getServerSideAuthTokens from './getServerSideAuthTokens'

const { publicRuntimeConfig } = getConfig()

class NextJSApolloLinkConfigurationFactory {
  static create(ctx): CreateApolloLinkConfiguration {
    if (ctx.req) {
      return this.createSSR(ctx)
    }

    return this.createCSR(ctx)
  }

  private static createSSR(ctx): CreateApolloLinkConfiguration {
    const authTokenResult = getServerSideAuthTokens(ctx)

    // During SSR getInitialProps will not have run yet, so we have to get this from the req
    const { contentLanguage } = getContentLanguage(parseCookies(ctx), ctx.req)

    if (authTokenResult.isFailure()) {
      logger.error(
        authTokenResult.error,
        'Error in NextJSApolloLinkConfigurationFactory'
      )

      return {
        tokenHelpersConfig: {
          getNextIdToken: () => {
            return null
          },
          hasRefreshToken: false,
          serverSideIdToken: null,
        },
        createHeadersLinkConfig: {
          contentLanguage,
          headers: ctx?.req?.headers,
          remoteAddress: ctx?.req?.connection?.remoteAddress,
          query: ctx?.req?.query,
          getServerSideCookies: (isBrowser: boolean) => {
            if (isBrowser) {
              return null
            }

            return null
          },
        },
      }
    }
    const { idToken, cookieHeader, refreshToken } = authTokenResult.value

    return {
      tokenHelpersConfig: {
        getNextIdToken: () => {
          return idToken
        },
        hasRefreshToken: !!refreshToken,
        serverSideIdToken: idToken,
      },
      createHeadersLinkConfig: {
        contentLanguage,
        headers: ctx?.req?.headers,
        remoteAddress: ctx?.req?.connection?.remoteAddress,
        query: ctx?.req?.query,
        getServerSideCookies: (isBrowser: boolean) => {
          if (isBrowser) {
            return null
          }

          return cookieHeader
        },
      },
    }
  }

  private static createCSR(ctx): CreateApolloLinkConfiguration {
    return {
      tokenHelpersConfig: {
        getNextIdToken: () => {
          return (
            parseCookies(ctx)?.[
              publicRuntimeConfig.NEXT_ID_TOKEN_COOKIE_NAME
            ] ?? null
          )
        },
        hasRefreshToken: ctx.hasRefreshToken ?? false,
        serverSideIdToken:
          parseCookies(ctx)?.[publicRuntimeConfig.NEXT_ID_TOKEN_COOKIE_NAME] ??
          null,
      },
      createHeadersLinkConfig: {
        contentLanguage: ctx.contentLanguage,
        headers: {
          cookie: document.cookie,
        },
        remoteAddress: null,
        query: null,
        getServerSideCookies: () => {
          return null
        },
      },
    }
  }
}

export default NextJSApolloLinkConfigurationFactory
