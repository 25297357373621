const padding = {
  pt: { s: 3, m: 4 },
  pb: { s: 4, m: 5 },
}

const gridPadding = {
  gridRowGap: { s: 4, m: 5 },
  ...padding,
}

export { gridPadding }
export default padding
