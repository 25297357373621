import { themes } from '@resident-advisor/design-system'

// adding an offset to the RA zIndex values to avoid conflicts with embeddable components
const embeddableZIndexOffset = 100

type KeyValue = {
  [key: string]: number
}

type ZIndexes = KeyValue & {
  source?: KeyValue
}

const zIndexes: ZIndexes = {
  ...themes.zIndex,
  ipsUnselected: -1, // outgoing ips fades underneath
  ipsSelected: 1, // incoming ips fades on top
  source: {
    tableHeader: 1,
    artistNameHeader: 2,
    filters: 3,
    nav: 4,
    primaryFilters: 3,
  },
  ipsPagination: 2,
  neutral: 0,
  archiveNavigationItem: 1,
  subNav: 2,
  stickyGroupHeader: 1,
  desktopBuyButton: 1,
  globalNavMenuHidden: 3, // global nav at top of page when menu is not shown
  stickyHeaderAd: 4,
  mobileBuyButton: 4,
  cornerNavModal: 5, // modal opened by corner nav toggle
  cornerNavToggle: 6, // must appear on top of corner nav modal to allow you to close it
  modal: 7, // default modal z index
  popoverItem: 7, // above modal for mobile event submissions
  taggableTextAreaLoadingBar: 7, // above modal for mobile event submissions
  taggableTextAreaDropdown: 8, // above loading bar
  loginModalInTicketsModal: 8, // on top of other modals
  topLoadingBar: 9,
  beaconButton: 10, // on top of regular pages/components
  proSideModal: 8, // on top of TicketKebabMenu
  toolTip: 8,
}

const addEmbeddableZIndexOffset = (zIndexesObject: ZIndexes): ZIndexes =>
  Object.entries(zIndexesObject).reduce((acc, [key, value]) => {
    if (value instanceof Object) {
      acc[key] = addEmbeddableZIndexOffset(value)
      return acc
    }
    acc[key] = value + embeddableZIndexOffset
    return acc
  }, {})

export default addEmbeddableZIndexOffset(zIndexes)
