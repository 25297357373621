import {
  useCallback,
  useState,
  useContext,
  createContext,
  useEffect,
  PropsWithChildren,
} from 'react'
import { IMutableContext, useUnleashClient } from '@unleash/proxy-client-react'
import merge from 'lodash/merge'
import isEqual from 'lodash/isEqual'

const FeatureSwitchesContext = createContext<{
  contextReady: boolean
  updateContext: (context: IMutableContext) => Promise<void>
}>({ contextReady: true, updateContext: () => Promise.resolve() })

const FeatureContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const client = useUnleashClient()
  const [contextReady, setContextReady] = useState(false)

  const updateContext = useCallback(
    async (context: IMutableContext) => {
      // Removing appName and environment as they are immutable and it throws a warning
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { appName, environment, ...currentContext } = client.getContext()

      const newContext = merge({}, currentContext, context)

      if (!isEqual(currentContext, newContext)) {
        setContextReady(false)
        return client.updateContext(newContext)
      }

      return Promise.resolve()
    },
    [client]
  )

  useEffect(() => {
    const onUpdate = () => {
      setContextReady(true)
    }
    client.on('update', onUpdate)
    return () => {
      client.off('update', onUpdate)
    }
  }, [client])

  return (
    <FeatureSwitchesContext.Provider value={{ contextReady, updateContext }}>
      {children}
    </FeatureSwitchesContext.Provider>
  )
}

const useFeatureSwitchesContext = () => useContext(FeatureSwitchesContext)

export { useFeatureSwitchesContext, FeatureContextProvider }
