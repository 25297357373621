import getConfig from 'next/config'
import ENVIRONMENT from 'enums/environment'
import { getAreaSafeName } from 'lib/utils'
import { destroyCookie, setCookie } from 'lib/cookieHelpers'
import cookieNames from 'lib/cookieNames'
import { setIterableCookie } from './iterableTracking'
import REGISTRATION_SOURCE from '../helpers/registration-source'

const { publicRuntimeConfig } = getConfig()

const WEB_MIXPANEL_PLATFORM = 'Web'
const BASE_SUPER_PROPERTIES = { Platform: WEB_MIXPANEL_PLATFORM }

const identify = (
  userId,
  {
    firstName,
    lastName,
    username,
    email,
    locale,
    dateRegistered,
    area,
    closestArea,
    registrationSource,
    dateOfBirth,
    artistIds,
    promoterIds,
    followedProfilesCount,
  } = {},
  mixpanel
) => {
  const distinctId = mixpanel.get_distinct_id()
  const areaName = getAreaSafeName(area, area?.country)
  const closestAreaName = getAreaSafeName(closestArea, closestArea?.country)

  if (userId && userId !== distinctId) {
    mixpanel.identify(userId)
  }

  mixpanel.people.set({
    $first_name: firstName,
    $last_name: lastName,
    $email: email,
    Username: username,
    Language: locale,
    Country: area?.country?.name,
    // only keeping this temporarily to make sure we don't lose tracking data for new users while changing to RA Area
    Area: areaName,
    'RA Area': areaName,
    'Detected RA Area': closestAreaName,
    Birthday: dateOfBirth,
    'Registration Date': dateRegistered,
    'Registration Method':
      REGISTRATION_SOURCE[registrationSource] ?? registrationSource,
    'Artist Ids': artistIds,
    'Promoter Ids': promoterIds,
  })

  mixpanel.register({ ...BASE_SUPER_PROPERTIES, IsLoggedIn: true })
  setRAMPCookie(userId)

  if (userId && window?._iaq) {
    let iterableEmail = email
    if (publicRuntimeConfig.ENVIRONMENT !== ENVIRONMENT.production) {
      // don't use real email address as iterable doesn't
      // have a real sandbox environment
      iterableEmail = `${publicRuntimeConfig.ENVIRONMENT}+${email?.replace(
        '@',
        '_at_'
      )}@ra.co`
    }

    window._iaq.push([
      'identify',
      iterableEmail,
      {
        userId,
        firstName,
        lastName,
        locale,
        area: {
          id: area?.id,
          name: areaName,
        },
        followedProfilesCount,
      },
    ])
  }
}

const identifyGuest = (guestArea, mixpanel) => {
  const distinctId = mixpanel.get_distinct_id()

  mixpanel.identify(distinctId)
  mixpanel.people.set({
    // only keeping this temporarily to make sure we don't lose tracking data for new users while changing to RA Area
    Area: guestArea,
    'RA Area': '',
    'Detected RA Area': guestArea,
  })

  mixpanel.register({ ...BASE_SUPER_PROPERTIES, IsLoggedIn: false })

  setRAMPCookie(distinctId)
}

const resetIdentity = () => {
  if (typeof window !== 'undefined' && window.mixpanel) {
    window.mixpanel.reset()
  }

  destroyCookie(cookieNames.ra_mp_userid)
  destroyCookie(cookieNames.ra_mp_experimentdata)

  setIterableCookie()
}

// We store the mixpanel user id in a cookie so that we can retrieve it when sending tracking events
// from the server side.
const setRAMPCookie = (userId) =>
  setCookie(cookieNames.ra_mp_userid, userId, {
    maxAge: 31536000, // 1 year in ms
  })

export { identify, resetIdentity, identifyGuest }
