import { useI18nContext } from 'context/I18nContext'
import { useEffect, useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import SupportedLocales from 'enums/supported-locales'

// Stored in a context so the same locale data is re-used whenever the useDateFnsLocale is called
const DateFnsLocaleContext = createContext()

const DateFnsLocaleProvider = ({ children }) => {
  const i18nContext = useI18nContext()
  const lang = i18nContext?.state?.lang

  const [locale, setLocale] = useState(null)

  useEffect(() => {
    const loadLocale = async () => {
      let localeData

      // In order for webpack to determine the chunks we need to be explicit about the imports
      // so using a big switch statement to define them all
      switch (lang) {
        case SupportedLocales.English:
          localeData = await import('date-fns/locale/en-GB')
          break
        case SupportedLocales.Spanish:
        case SupportedLocales.MexicanSpanish:
          // date-fns doesnt explicitly support es-MX
          // suggested to fallback to es instead
          localeData = await import('date-fns/locale/es')
          break
        case SupportedLocales.French:
          localeData = await import('date-fns/locale/fr')
          break
        case SupportedLocales.German:
          localeData = await import('date-fns/locale/de')
          break
        case SupportedLocales.Japanese:
          localeData = await import('date-fns/locale/ja')
          break
        case SupportedLocales.Italian:
          localeData = await import('date-fns/locale/it')
          break
        case SupportedLocales.Dutch:
          localeData = await import('date-fns/locale/nl')
          break
        case SupportedLocales.Portuguese:
          localeData = await import('date-fns/locale/pt')
          break
        case SupportedLocales.BrazilianPortuguese:
          localeData = await import('date-fns/locale/pt-BR')
          break
        case 'pl':
          localeData = await import('date-fns/locale/pl')
          break
        default:
          localeData = await import('date-fns/locale/en-GB')
      }

      setLocale(localeData)
    }

    loadLocale()
  }, [lang, setLocale])

  return (
    <DateFnsLocaleContext.Provider value={locale}>
      {children}
    </DateFnsLocaleContext.Provider>
  )
}

const useDateFnsLocale = () => {
  const context = useContext(DateFnsLocaleContext)
  return context
}

DateFnsLocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { DateFnsLocaleProvider }
export default useDateFnsLocale
