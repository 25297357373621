import getConfig from 'next/config'
import Script from 'next/script'

const { publicRuntimeConfig } = getConfig()

// https://morganfeeney.com/how-to/integrate-google-tag-manager-with-next-js
// https://developers.google.com/tag-platform/devguides/privacy

const GtagScript = () => {
  // prettier/copilot keeps fighting over this line if I put it directly in the script
  const gtmId = publicRuntimeConfig.GTM_ID

  return (
    <Script id="google-tag-manager" strategy="afterInteractive">
      {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}

      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-${gtmId}')
  `}
    </Script>
  )
}

export default GtagScript
