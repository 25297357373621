import {
  createContext,
  useState,
  useContext,
  SetStateAction,
  Dispatch,
  PropsWithChildren,
} from 'react'

const AnimationContext = createContext<AnimationContextValue>([
  undefined,
  undefined,
])

type AnimationContextValue = [boolean, Dispatch<SetStateAction<boolean>>]

const AnimationContextProvider = ({
  children,
}: PropsWithChildren<Record<string, never>>) => {
  const [isAnimating, setIsAnimating] = useState(false)

  return (
    <AnimationContext.Provider value={[isAnimating, setIsAnimating]}>
      {children}
    </AnimationContext.Provider>
  )
}

const useAnimationContext = () => useContext(AnimationContext)

export { useAnimationContext, AnimationContextProvider }
