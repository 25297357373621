import Script from 'next/script'
import { PropTypes } from 'prop-types'
import BEACON_ACTION from 'enums/beacon-action'
import testIds from 'enums/testIds'

const MODAL_DEFAULT_OPTIONS = { type: 'modal' }
const BEACON_JS_ID = 'beaconScriptLoad'

const Beacon = ({ initSettings: { id, config, modalId }, onLoad }) => {
  return (
    <Script
      strategy="afterInteractive"
      id={BEACON_JS_ID}
      src="/lib/helpscout-beacon-v2.js"
      data-testid={testIds.beaconJs}
      onLoad={() => {
        if (config) window.Beacon(BEACON_ACTION.config, config)
        window.Beacon(BEACON_ACTION.init, id)
        if (modalId)
          window.Beacon(BEACON_ACTION.article, modalId, MODAL_DEFAULT_OPTIONS)
        onLoad()
      }}
    />
  )
}

Beacon.propTypes = {
  initSettings: PropTypes.shape({
    id: PropTypes.string,
    config: PropTypes.object,
    modalId: PropTypes.string,
  }),
  onLoad: PropTypes.func,
}

export { Beacon, BEACON_JS_ID, MODAL_DEFAULT_OPTIONS }
