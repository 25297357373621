import { gql } from '@apollo/client'
import { AREA_FRAGMENT } from 'queries/GetAreaQuery'

// If updating fields used here please also update the default data in areaDefaults.js
const GET_CLOSEST_AREA_QUERY = gql`
  query GET_CLOSEST_AREA_QUERY {
    areas(limit: 1) {
      ...areaFields
    }
  }
  ${AREA_FRAGMENT}
`

export default GET_CLOSEST_AREA_QUERY
