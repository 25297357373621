import { gql } from '@apollo/client'

const AREA_FRAGMENT = gql`
  fragment areaFields on Area {
    id
    name
    urlName
    ianaTimeZone
    blurb
    country {
      id
      name
      urlCode
      requiresCookieConsent
      currency {
        id
        code
        exponent
        symbol
      }
    }
  }
`

const GET_AREA_QUERY = gql`
  query GET_AREA_QUERY($id: ID, $areaUrlName: String, $countryUrlCode: String) {
    area(id: $id, areaUrlName: $areaUrlName, countryUrlCode: $countryUrlCode) {
      ...areaFields
    }
  }
  ${AREA_FRAGMENT}
`

export { AREA_FRAGMENT }
export default GET_AREA_QUERY
