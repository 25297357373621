import { parseCookies } from 'nookies'
import cookieNames from 'lib/cookieNames'

export type GetTokenHelpersConfiguration = {
  hasRefreshToken?: boolean
  serverSideIdToken?: string
  getNextIdToken: () => string
}

const getTokenHelpers = (config: GetTokenHelpersConfiguration) => {
  const hasRefreshToken = config.hasRefreshToken ?? null

  return {
    getIdToken: () => config.getNextIdToken(),
    hasRefreshToken: () => {
      if (typeof hasRefreshToken === 'boolean') {
        if (hasRefreshToken) {
          // We have the refresh token from the initial page load
          return true
        }

        if (!hasRefreshToken) {
          // We have the refresh token due to a login in the session
          return parseCookies()?.[cookieNames.successfulLoginDuringSession]
        }
      }

      return false
    },
  }
}

export default getTokenHelpers
