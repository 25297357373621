/* eslint-disable @typescript-eslint/no-explicit-any */
import { IMutableContext, IToggle } from 'unleash-proxy-client'
import { User } from 'lib/user'
import { Messages } from 'lib/locale/types'
import { AppContextType } from 'next/dist/shared/lib/utils'

// Matches types from the frontend FeatureSwitchesProvider.tsx
type FeatureSwitches = {
  initialContext: IMutableContext
  values: IToggle[]
}
// The props that are required for rendering the app
// As the functionality is migrated to the official server version this
// type will be updated
type AppInitialProps = {
  pageProps: any
  serverTime: number
  isBot: boolean
  areaId: string
  eventsAreaId: string
  contentLanguage: string
  contentLanguageCookie: string
  currentDomain: string
  featureSwitches: FeatureSwitches
  user?: User
  hasRefreshToken: boolean
  locale: string
  messages: Messages
}

interface IGetInitialProps {
  (appContext: AppContextType): Promise<AppInitialProps>
}

export { default } from './official-server'
export type { IGetInitialProps, AppInitialProps, FeatureSwitches }
