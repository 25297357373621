import { createContext, useContext } from 'react'
import PropTypes from 'prop-types'

const DomainContext = createContext()

const useDomainContext = () => useContext(DomainContext)

const DomainProvider = ({ children, currentDomain }) => {
  return (
    <DomainContext.Provider value={currentDomain}>
      {children}
    </DomainContext.Provider>
  )
}

DomainProvider.propTypes = {
  children: PropTypes.node.isRequired,
  currentDomain: PropTypes.string,
}

export { useDomainContext, DomainProvider }
