import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

export default {
  ENGLISH: {
    iso: 'en',
    value: 'ENGLISH',
    webAddress: publicRuntimeConfig.NEXT_WEB_ADDRESS,
  },
  JAPANESE: {
    iso: 'ja',
    value: 'JAPANESE',
    webAddress: publicRuntimeConfig.NEXT_WEB_ADDRESS_JP,
  },
}
