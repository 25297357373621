export default {
  default: {
    pt: 3,
    pb: 3,
    mt: 5,
  },
  ips: {
    pt: {
      s: 5,
      m: 7,
    },
    pb: {
      s: 3,
      m: 4,
    },
  },
  tall: {
    pt: { s: 5, m: 6 },
    pb: 3,
  },
  profile: {
    minHeight: '320px',
    pt: { s: 3, m: 6 },
    pb: 3,
    photoMb: { s: '76px', m: 0 },
    photoMl: { m: 3 },
  },
  condensed: {
    height: 60,
  },
}
