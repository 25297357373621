import { DFPSlotsProvider, DFPManager } from 'react-dfp'
import getConfig from 'next/config'
import { PropsWithChildren } from 'react'
import { useTimeoutFn } from 'react-use'

const { publicRuntimeConfig } = getConfig()

const AdContextProvider = ({ children }: PropsWithChildren<object>) => {
  useTimeoutFn(() => {
    DFPManager.load()
  }, publicRuntimeConfig.ADS_DELAY_MS)

  return (
    <DFPSlotsProvider
      autoLoad={false}
      dfpNetworkId={publicRuntimeConfig.DFP_ID}
      collapseEmptyDivs
      lazyLoad={{
        fetchMarginPercent: 100, // Fetch slots within 1 viewports.
        renderMarginPercent: 100, // Render slots within 1 viewports.
        mobileScaling: 2.0, // Double the above values on mobile.
      }}
    >
      {children}
    </DFPSlotsProvider>
  )
}

export default AdContextProvider
