import { gql } from '@apollo/client'
import { AREA_FRAGMENT } from 'queries/GetAreaQuery'

const GET_BOTH_AREAS = gql`
  query GET_BOTH_AREAS($areaId: ID, $eventsAreaId: ID) {
    area(id: $areaId) {
      ...areaFields
    }
    eventsArea: area(id: $eventsAreaId) {
      ...areaFields
    }
  }
  ${AREA_FRAGMENT}
`

const GET_EVENTS_AREA = gql`
  query GET_EVENTS_AREA($id: ID) {
    eventsArea: area(id: $id) {
      ...areaFields
    }
  }
  ${AREA_FRAGMENT}
`

export { GET_BOTH_AREAS, GET_EVENTS_AREA }
