// data-button-tracking-id=""

const buttonTrackingIds = {
  closeFilterModal: 'close-filter-modal',
  closeLoginModal: 'close-login-modal',
  closeModal: 'close-modal',
  createPaymentUserSubmit: 'create-payment-user-submit',
  eventInterestButton: 'event-interest-button',
  follow: 'follow',
  unfollow: 'unfollow',
  forgotPasswordFormSubmit: 'forgot-password-form-submit',
  loginFormSubmit: 'login-form-submit',
  logout: 'logout',
  openClubStatusFilterModal: 'open-club-status-filter-modal',
  openDateFilterModal: 'open-date-filter-modal',
  openFollowingTypeFilterModal: 'open-following-type-filter-modal',
  openLanguageFilterModal: 'open-language-filter-modal',
  openListingFilterModal: 'open-listing-filter-modal',
  openLoginModal: 'open-login-modal',
  openMobileNavSearchModal: 'open-mobile-nav-search-modal',
  openMonthFilterModal: 'open-month-filter-modal',
  openNavModal: 'open-nav-modal',
  openNavSearchModal: 'open-nav-search-modal',
  openNewsTypeFilterModal: 'open-news-type-filter-modal',
  openUserNavModal: 'open-user-nav-modal',
  openYearFilterModal: 'open-year-filter-modal',
  pitchFormSubmit: 'pitch-form-submit',
  accountHolderFormUpdate: 'account-holder-form-update',
  accountHolderFormSubmit: 'account-holder-form-submit',
  accountHolderFormCancel: 'account-holder-form-cancel',
  removeFilterOption: 'remove-filter-option',
  resetPasswordFormSubmit: 'reset-password-form-submit',
  resendVerificationEmailFormSubmit: 'resend-verification-email-form-submit',
  setLocale: 'set-locale',
  showBookingInfo: 'show-booking-info',
  toggleMobileNavModal: 'toggle-mobile-nav-modal',
  toggleShowFilterList: 'toggle-show-filter-list',
  toggleTicketsModal: 'toggle-tickets-modal',
  toggleGenreFilterModal: 'toggle-genre-filter-modal',
  toggleEventTypeFilterModal: 'toggle-event-type-filter-modal',
  toggleDistanceFilterModal: 'toggle-distance-filter-modal',
  verifyEmailCompleteFormSubmit: 'verify-email-complete-form-submit',
  viewAllLocations: 'view-all-locations',
}

export default buttonTrackingIds
