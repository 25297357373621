import { onError } from '@apollo/client/link/error'
import omit from 'lodash/omit'
import getConfig from 'next/config'
import { logger } from 'lib/logger'

const { publicRuntimeConfig } = getConfig()

const createErrorLink = () =>
  onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        logger.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, OperationName: ${
            operation?.operationName
          }, Variables: ${JSON.stringify(
            omit(
              operation?.variables,
              publicRuntimeConfig.GRAPHQL_LOGS_CONFIG.REDACTED_VARIABLE_PATHS
            )
          )} Path: ${path}`
        )
      )
    }

    if (networkError) {
      logger.error(`[Network error]: ${networkError}`)
    }
  })

export default createErrorLink
