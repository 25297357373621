import { gql } from '@apollo/client'
import { AREA_FRAGMENT } from 'queries/GetAreaQuery'

// If updating fields used here please also update the default data in areaDefaults.js
const GET_AUTOLOCATE_AREA_QUERY = gql`
  query GET_AUTOLOCATE_AREA_QUERY {
    autolocateArea {
      ...areaFields
    }
  }
  ${AREA_FRAGMENT}
`
export default GET_AUTOLOCATE_AREA_QUERY
