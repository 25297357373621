import { captureError } from 'lib/logger'

// Prevent errors caused by Google Translate's text replacement from breaking the page
// https://github.com/facebook/react/issues/11538#issuecomment-417504600
// https://bugs.chromium.org/p/chromium/issues/detail?id=872770

/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
const captureTextReplacementErrors = () => {
  const originalRemoveChild = Node.prototype.removeChild
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      captureError(
        new Error(
          'Text replacement error: Cannot remove a child from a different parent'
        )
      )
      return child
    }
    return originalRemoveChild.apply(this, arguments)
  }

  const originalInsertBefore = Node.prototype.insertBefore
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      captureError(
        new Error(
          'Text replacement error: Cannot insert before a reference node from a different parent'
        )
      )
      return newNode
    }
    return originalInsertBefore.apply(this, arguments)
  }
}

export default captureTextReplacementErrors
