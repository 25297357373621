import { sizes, colors } from '@resident-advisor/design-system'

const variants = {
  Leader: {
    display: 'flex',
    show: {
      s: true,
      m: true,
      l: true,
    },
    color: colors.black,
    adUnit: 'Leader',
    sizeMapping: [
      {
        viewport: [sizes.s, 0],
        sizes: [
          [320, 100],
          [320, 50],
        ],
      },
      {
        viewport: [sizes.m, 0],
        sizes: [
          [952, 250],
          [952, 125],
        ],
      },
      {
        viewport: [sizes.l, 0],
        sizes: [
          [970, 250],
          [952, 250],
          [728, 90],
          [952, 152],
          [1180, 250],
        ],
      },
    ],
    reservedSizes: {
      'min-width': { s: 320, m: 952, l: 728 },
      'min-height': { s: 50, m: 125, l: 90 },
    },
  },
  InPageSmall: {
    display: { s: 'flex', m: 'none' },
    show: {
      s: true,
      m: false,
      l: false,
    },
    adUnit: 'Inpage',
    sizeMapping: [
      {
        viewport: [sizes.s, 0],
        sizes: [
          [300, 250],
          [300, 600],
          [320, 100],
          [320, 50],
        ],
      },
    ],
    reservedSizes: {
      'min-width': { s: 320 },
      'min-height': { s: 50 },
    },
  },
  InPageHorizontal: {
    display: { s: 'flex', m: 'none', l: 'flex' },
    show: {
      s: true,
      m: false,
      l: true,
    },
    adUnit: 'Inpage',
    sizeMapping: [
      {
        viewport: [sizes.s, 0],
        sizes: [
          [300, 250],
          [300, 600],
          [320, 100],
          [320, 50],
        ],
      },
      {
        viewport: [sizes.l, 0],
        sizes: [
          [728, 90],
          [952, 152],
          [1180, 250],
          [952, 250],
          [970, 250],
        ],
      },
    ],
    reservedSizes: {
      'min-width': { s: 320, l: 728 },
      'min-height': { s: 50, l: 90 },
    },
  },
  ECTOSlot1: {
    display: 'flex',
    adUnit: 'ECTO_Slot_1',
    show: {
      s: true,
      m: true,
      l: true,
    },
    sizeMapping: [
      {
        viewport: [sizes.s, 0],
        sizes: [
          [300, 250],
          [300, 600],
        ],
      },
      {
        viewport: [sizes.m, 0],
        sizes: [[728, 90]],
      },
      {
        viewport: [sizes.l, 0],
        sizes: [
          [952, 152],
          [952, 250],
          [970, 250],
        ],
      },
    ],
    reservedSizes: {
      'min-width': { s: 300, m: 728, l: 952 },
      'min-height': { s: 250, m: 90, l: 152 },
    },
  },
  EH_MPU_1: {
    display: 'flex',
    show: {
      s: true,
      m: true,
      l: true,
    },
    adUnit: 'EH_MPU_1',
    sizeMapping: [
      {
        viewport: [sizes.s, 0],
        sizes: [
          [300, 250],
          [300, 600],
        ],
      },
    ],
    reservedSizes: { 'min-width': { s: 300 }, 'min-height': { s: 250 } },
  },
  EH_MPU_2: {
    display: 'flex',
    show: {
      s: true,
      m: true,
      l: true,
    },
    adUnit: 'EH_MPU_2',
    sizeMapping: [
      {
        viewport: [sizes.s, 0],
        sizes: [
          [300, 250],
          [300, 600],
        ],
      },
    ],
    reservedSizes: { 'min-width': { s: 300 }, 'min-height': { s: 250 } },
  },
  EH_MPU_3: {
    display: 'flex',
    show: {
      s: true,
      m: true,
      l: true,
    },
    adUnit: 'EH_MPU_3',
    sizeMapping: [
      {
        viewport: [sizes.s, 0],
        sizes: [
          [300, 250],
          [300, 600],
        ],
      },
    ],
    reservedSizes: { 'min-width': { s: 300 }, 'min-height': { s: 250 } },
  },
  EH_MPU_4: {
    display: 'flex',
    show: {
      s: true,
      m: true,
      l: true,
    },
    adUnit: 'EH_MPU_4',
    sizeMapping: [
      {
        viewport: [sizes.s, 0],
        sizes: [
          [300, 250],
          [300, 600],
        ],
      },
    ],
    reservedSizes: { 'min-width': { s: 300 }, 'min-height': { s: 250 } },
  },
  EH_MPU_5: {
    display: 'flex',
    show: {
      s: true,
      m: true,
      l: true,
    },
    adUnit: 'EH_MPU_5',
    sizeMapping: [
      {
        viewport: [sizes.s, 0],
        sizes: [
          [300, 250],
          [300, 600],
        ],
      },
    ],
    reservedSizes: { 'min-width': { s: 300 }, 'min-height': { s: 250 } },
  },
  EH_MPU_6: {
    display: 'flex',
    show: {
      s: true,
      m: true,
      l: true,
    },
    adUnit: 'EH_MPU_6',
    sizeMapping: [
      {
        viewport: [sizes.s, 0],
        sizes: [
          [300, 250],
          [300, 600],
        ],
      },
    ],
    reservedSizes: { 'min-width': { s: 300 }, 'min-height': { s: 250 } },
  },
  EH_MPU_7: {
    display: 'flex',
    show: {
      s: true,
      m: true,
      l: true,
    },
    adUnit: 'EH_MPU_7',
    sizeMapping: [
      {
        viewport: [sizes.s, 0],
        sizes: [
          [300, 250],
          [300, 600],
        ],
      },
    ],
    reservedSizes: { 'min-width': { s: 300 }, 'min-height': { s: 250 } },
  },
  EH_MPU_8: {
    display: 'flex',
    show: {
      s: true,
      m: true,
      l: true,
    },
    adUnit: 'EH_MPU_8',
    sizeMapping: [
      {
        viewport: [sizes.s, 0],
        sizes: [
          [300, 250],
          [300, 600],
        ],
      },
    ],
    reservedSizes: { 'min-width': { s: 300 }, 'min-height': { s: 250 } },
  },
  MPU_General_Large: {
    display: { s: 'none', m: 'none', l: 'flex' },
    show: {
      s: false,
      m: false,
      l: true,
    },
    adUnit: 'MPU_General',
    sizes: [
      [300, 250],
      [300, 600],
    ],
    reservedSizes: { 'min-width': { s: 300 }, 'min-height': { s: 250 } },
  },
  MPU_General_Small: {
    display: { s: 'flex', m: 'none' },
    show: {
      s: true,
      m: false,
      l: false,
    },
    adUnit: 'MPU_General',
    sizes: [
      [300, 250],
      [300, 600],
    ],
    reservedSizes: { 'min-width': { s: 300 }, 'min-height': { s: 250 } },
  },
  MPU_General: {
    display: 'flex',
    show: {
      s: true,
      m: true,
      l: true,
    },
    adUnit: 'MPU_General',
    sizes: [
      [300, 250],
      [300, 600],
    ],
    reservedSizes: { 'min-width': { s: 300 }, 'min-height': { s: 250 } },
  },
  MPU_General_2: {
    display: 'flex',
    show: {
      s: true,
      m: true,
      l: true,
    },
    adUnit: 'MPU_General_2',
    sizes: [
      [300, 250],
      [300, 600],
    ],
    reservedSizes: { 'min-width': { s: 300 }, 'min-height': { s: 250 } },
  },
  HPTO_2: {
    display: 'flex',
    show: {
      s: true,
      m: true,
      l: true,
    },
    adUnit: 'HPTO_Slot_2',
    sizeMapping: [
      {
        viewport: [sizes.s, 0],
        sizes: [
          [300, 250],
          [300, 600],
        ],
      },
      {
        viewport: [sizes.m, 0],
        sizes: [[728, 90]],
      },
      {
        viewport: [sizes.l, 0],
        sizes: [
          [952, 152],
          [952, 250],
          [970, 250],
        ],
      },
    ],
    reservedSizes: {
      'min-width': { s: 300, m: 728, l: 952 },
      'min-height': { s: 250, m: 90, l: 152 },
    },
  },
  HPTO_3: {
    display: 'flex',
    show: {
      s: true,
      m: true,
      l: true,
    },
    adUnit: 'HPTO_Slot_3',
    sizeMapping: [
      {
        viewport: [sizes.s, 0],
        sizes: [
          [300, 250],
          [300, 600],
        ],
      },
      {
        viewport: [sizes.m, 0],
        sizes: [[728, 90]],
      },
      {
        viewport: [sizes.l, 0],
        sizes: [
          [952, 152],
          [952, 250],
          [970, 250],
        ],
      },
    ],
    reservedSizes: {
      'min-width': { s: 300, m: 728, l: 952 },
      'min-height': { s: 250, m: 90, l: 152 },
    },
  },
  HPTO_4: {
    display: 'flex',
    show: {
      s: true,
      m: true,
      l: true,
    },
    adUnit: 'HPTO_Slot_4',
    sizeMapping: [
      {
        viewport: [sizes.s, 0],
        sizes: [
          [300, 250],
          [300, 600],
        ],
      },
      {
        viewport: [sizes.m, 0],
        sizes: [[728, 90]],
      },
      {
        viewport: [sizes.l, 0],
        sizes: [
          [952, 152],
          [952, 250],
          [970, 250],
        ],
      },
    ],
    reservedSizes: {
      'min-width': { s: 300, m: 728, l: 952 },
      'min-height': { s: 250, m: 90, l: 152 },
    },
  },
  HPTO_5: {
    display: 'flex',
    show: {
      s: true,
      m: true,
      l: true,
    },
    adUnit: 'HPTO_Slot_5',
    sizeMapping: [
      {
        viewport: [sizes.s, 0],
        sizes: [
          [300, 250],
          [300, 600],
        ],
      },
      {
        viewport: [sizes.m, 0],
        sizes: [[728, 90]],
      },
      {
        viewport: [sizes.l, 0],
        sizes: [
          [952, 152],
          [952, 250],
          [970, 250],
        ],
      },
    ],
    reservedSizes: {
      'min-width': { s: 300, m: 728, l: 952 },
      'min-height': { s: 250, m: 90, l: 152 },
    },
  },
}

export const withName = (adVariant, name) => ({
  ...adVariant,
  adUnit: name,
})

export default variants
