import PropTypes, { InferProps } from 'prop-types'
import { Button, Icon, CloseIcon } from '@resident-advisor/design-system'
import styled from 'styled-components'

const CloseButton = ({
  closeIcon,
  iconSize,
  ...props
}: CloseButtonPropTypes) => (
  <StyledButton px={0} {...props}>
    <Icon size={iconSize} Component={closeIcon} />
  </StyledButton>
)

const closeButtonPropTypes = {
  onClick: PropTypes.func.isRequired,
  closeIcon: PropTypes.elementType,
  iconSize: PropTypes.number,
  variant: PropTypes.string,
  description: PropTypes.string,
  p: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

type CloseButtonPropTypes = InferProps<typeof closeButtonPropTypes>

CloseButton.propTypes = closeButtonPropTypes

CloseButton.defaultProps = {
  closeIcon: CloseIcon,
  iconSize: 27,
}

const StyledButton = styled(Button)`
  min-width: 0;
  height: auto;
`

StyledButton.displayName = 'Button'

export default CloseButton
