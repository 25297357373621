import { createContext, PropsWithChildren, useContext, useState } from 'react'

const StickyContext = createContext<StickyContextValue>({
  offset: undefined,
  setOffset: undefined,
})

const useStickyContext = () => useContext(StickyContext)

const StickyContextProvider: React.FC<
  PropsWithChildren<StickyContextProviderProps>
> = ({
  children,
  offset: initialOffset = 0,
}: PropsWithChildren<StickyContextProviderProps>) => {
  const [offset, setOffset] = useState(initialOffset)

  return (
    <StickyContext.Provider value={{ offset, setOffset }}>
      {children}
    </StickyContext.Provider>
  )
}

type StickyContextValue = {
  offset: number
  setOffset: (offset: number) => void
}

type StickyContextProviderProps = {
  offset?: number
}

export { useStickyContext, StickyContextProvider }
