import arrayHasData from 'lib/arrayHasData'
import { useEffect, useState } from 'react'

const useDirectNavigationDuration = (enabled: boolean) => {
  const [perfObserver, setPerfObserver] = useState<PerformanceObserver | null>(
    null
  )
  const [loadDurationMs, setLoadDuration] = useState<number | null>(null)

  const processPerformanceEntry = (entry: PerformanceNavigationTiming) => {
    setLoadDuration(entry.loadEventEnd - entry.startTime)
  }

  useEffect(() => {
    if (enabled) {
      if (!loadDurationMs) {
        const performanceEntries = performance.getEntriesByType('navigation')

        if (loadEventEndFired(performanceEntries)) {
          // If the page renders quickly, it might have already 'finished' before the useEffect runs
          processPerformanceEntry(performanceEntries[0])
        } else if (!perfObserver) {
          // The useEffect is running before the browser considers the page 'finished', so setup a
          // PerformanceObserver to record it once its finished
          setPerfObserver(createPerformanceOberver(processPerformanceEntry))
        }
      }

      if (loadDurationMs && perfObserver) {
        // Cleanup the perfObserver if loadDuration has been set
        perfObserver.disconnect()
        setPerfObserver(null)
      }

      return () => {
        // Cleanup the perfObserver if component is unmounted
        if (perfObserver) {
          perfObserver.disconnect()
        }
      }
    }

    // return undefined so that the function has a consistent return type https://stackoverflow.com/a/64886804/4942191
    return undefined
  }, [perfObserver, loadDurationMs, enabled])

  return enabled ? { loadDurationMs, loading: !loadDurationMs } : null
}

const createPerformanceOberver = (callback) => {
  const po = new PerformanceObserver((list) => {
    const entries = list.getEntries()

    // Navigation event will only fire once, so we can assume its the only entry in array
    // As we're only observing navigation events, the type will always be PerformanceNavigationTiming[]
    if (loadEventEndFired(entries as PerformanceNavigationTiming[])) {
      callback(entries[0])
    }
  })

  po.observe({ entryTypes: ['navigation'] })
  return po
}

const loadEventEndFired = (performanceEntry: PerformanceNavigationTiming[]) =>
  arrayHasData(performanceEntry) && performanceEntry[0].loadEventEnd > 0

export default useDirectNavigationDuration
