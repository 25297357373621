import { PropsWithChildren } from 'react'
import {
  FlagProvider,
  IMutableContext,
  IToggle,
} from '@unleash/proxy-client-react'
import getConfig from 'next/config'
import { parseCookies } from 'nookies'
import cookieNames from 'lib/cookieNames'
import { FeatureContextProvider } from './FeatureSwitchesContext'

const {
  publicRuntimeConfig: { UNLEASH_PROXY },
} = getConfig()

const FeatureSwitchesProvider = ({
  children,
  context,
  toggles,
}: PropsWithChildren<{ context: IMutableContext; toggles: IToggle[] }>) => (
  <FlagProvider
    config={{
      url: UNLEASH_PROXY.URL,
      clientKey: UNLEASH_PROXY.CLIENT_KEY,
      refreshInterval: UNLEASH_PROXY.REFRESH_INTERVAL,
      appName: 'ra-next',
      bootstrap: toggles,
      context: {
        ...context,
        /**
         * Always get the session ID from the browser, because it's generated on the server
         * so page caching means it can be out of date during SSR
         */
        sessionId: parseCookies()?.[cookieNames.sessionId],
      },
    }}
  >
    <FeatureContextProvider>{children}</FeatureContextProvider>
  </FlagProvider>
)

export default FeatureSwitchesProvider
