import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import BEACON_ACTION from 'enums/beacon-action'
import { logger } from 'lib/logger'
import { useRouter } from 'next/router'
import canUseDom from 'lib/canUseDom'
import { Beacon, MODAL_DEFAULT_OPTIONS } from './Beacon'
import { beacon, canUseBeacon } from './beaconHelper'

const BeaconContext = createContext()
const defaultBeaconConfig = {
  display: {
    style: 'manual',
  },
}

const BeaconProvider = ({ children, helpScoutBeacons }) => {
  const { pathname } = useRouter()
  const [initSettings, setInitSettings] = useState(null)
  const [shouldShowBeaconButton, setShouldShowBeaconButton] = useState(true)
  const [loadedBeaconScript, setLoadedBeaconScript] = useState(false)
  const [beaconLoading, setBeaconLoading] = useState(true)
  const [open, setOpen] = useState(false)

  const helpScoutBeaconConfig = helpScoutBeacons?.find(
    (helpScoutConfig) => helpScoutConfig.url === pathname
  )

  const callBeacon = useCallback((action, id, options) => {
    if (!canUseDom()) return null
    if (!canUseBeacon()) return null
    try {
      return beacon(action, id, options)
    } catch (error) {
      logger.error(error)
    }
    return null
  }, [])

  useEffect(() => {
    if (!beaconLoading) beacon(BEACON_ACTION.config, defaultBeaconConfig)
  }, [beaconLoading, helpScoutBeaconConfig])

  useEffect(() => {
    const onOpen = () => setOpen(true)
    const onClose = () => setOpen(false)
    if (!beaconLoading) {
      callBeacon(BEACON_ACTION.on, BEACON_ACTION.open, onOpen)
      callBeacon(BEACON_ACTION.on, BEACON_ACTION.close, onClose)
    }
    return () => {
      if (!beaconLoading) {
        callBeacon(BEACON_ACTION.off, BEACON_ACTION.open, onOpen)
        callBeacon(BEACON_ACTION.off, BEACON_ACTION.close, onClose)
      }
    }
  }, [setOpen, callBeacon, beaconLoading])

  const initBeaconScript = (id, config, modalId) => {
    if (!loadedBeaconScript && id) {
      setInitSettings({ id, config, modalId })
      setLoadedBeaconScript(true)
    } else if (modalId) {
      callBeacon(BEACON_ACTION.init, id)
      callBeacon(BEACON_ACTION.config, defaultBeaconConfig)
      callBeacon(BEACON_ACTION.article, modalId, MODAL_DEFAULT_OPTIONS)
    }
  }

  const toggleBeacon = () => {
    callBeacon(BEACON_ACTION.toggle)
  }

  const showBeaconButton = () => setShouldShowBeaconButton(true)
  const hideBeaconButton = () => setShouldShowBeaconButton(false)

  const contextValue = {
    callBeacon,
    initBeaconScript,
    shouldShowBeaconButton,
    showBeaconButton,
    hideBeaconButton,
    helpScoutBeaconConfig,
    loading: beaconLoading,
    toggleBeacon,
    open,
  }

  return (
    <BeaconContext.Provider value={contextValue}>
      {loadedBeaconScript && (
        <Beacon
          initSettings={initSettings}
          onLoad={() => setBeaconLoading(false)}
        />
      )}
      {children}
    </BeaconContext.Provider>
  )
}

BeaconProvider.propTypes = {
  children: PropTypes.node.isRequired,
  helpScoutBeacons: PropTypes.arrayOf(
    PropTypes.shape({
      beaconId: PropTypes.string,
      name: PropTypes.string,
    })
  ),
}

const useBeaconContext = (config) => {
  const context = useContext(BeaconContext)
  const { loading, callBeacon } = context

  useEffect(() => {
    if (config && !loading) {
      callBeacon(BEACON_ACTION.config, config)
    }
  }, [config, loading, callBeacon])

  return context
}

export { BeaconProvider, useBeaconContext, defaultBeaconConfig }
