const REGISTRATION_SOURCE = {
  UNSPECIFIED: 'Unspecified',
  WEBSTANDARD: 'Web Standard',
  WEBCHECKOUT: 'Web Checkout',
  WEBFACEBOOK: 'Web Facebook',
  RAGUIDESTANDARD: 'RA Guide Standard',
  RAGUIDECHECKOUT: 'RA Guide Checkout',
  RAGUIDEFACEBOOK: 'RA Guide Facebook',
  RAGUIDEGUESTCHECKOUT: 'RA Guide Guest Checkout',
  RAGUIDEEXGUESTCHECKOUT: 'RA Guide Ex Guest Checkout',
  WIDGET: 'Widget',
  RANEXTSTANDARD: 'RA Next Standard',
  RANEXTFACEBOOK: 'RA Next Facebook',
  RAGUIDECHECKOUTFACEBOOK: 'RA Guide Checkout Facebook',
  RAGUIDEAPPLE: 'RA Guide Apple',
  RAGUIDECHECKOUTAPPLE: 'RA Guide Checkout Apple',
}

export default REGISTRATION_SOURCE
