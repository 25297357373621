const AD_TARGETING_KEYS = Object.freeze({
  PAGE: 'Page',
  CATEGORY: 'Category',
  SECTION: 'Section',
  CONTENT: 'Content',
  COUNTRY: 'Country',
  AREA: 'Area',
  IS_ARTIST: 'IsArtist',
  IS_LABEL: 'IsLabel',
  IS_PROMOTER: 'IsPromoter',
  ARTIST: 'Artist',
  PROMOTER: 'Promoter',
  CLUB: 'Club',
  EVENT_TYPE: 'EventType',
  GENRES: 'Genres',
})

export default AD_TARGETING_KEYS
