import React, { ErrorInfo, PropsWithChildren } from 'react'
import { captureError } from 'lib/logger'
import ErrorType from './error-type'

// Taken and modified from DataDog RUM integration example
// https://github.com/DataDog/rum-react-integration-examples
class LoggingErrorBoundary extends React.Component<
  PropsWithChildren<LoggingErrorBoundaryProps>,
  LoggingErrorBoundaryState
> {
  static getDerivedStateFromError(error): LoggingErrorBoundaryState {
    return { hasError: true, error }
  }

  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  private getErrorInfo(errorInfo: ErrorInfo) {
    const { errorType } = this.props

    if (!errorType) {
      return errorInfo
    }

    if (errorInfo && typeof errorInfo === 'object') {
      return { ...errorInfo, errorType }
    }

    return { errorType }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { onError, additionalContext } = this.props

    const contextWithError = {
      err: error,
      ...this.getErrorInfo(errorInfo),
      ...(additionalContext && { additionalContext }),
    }

    captureError(contextWithError, 'Error boundary')

    if (onError && typeof onError === 'function') {
      onError(error)
    }
  }

  render() {
    const { hasError, error } = this.state
    const { children, fallback } = this.props

    if (!hasError) {
      return children
    }

    if (typeof fallback === 'function') {
      return fallback(error)
    }

    return null
  }
}

type LoggingErrorBoundaryProps = {
  fallback?: (error: object) => JSX.Element
  onError: (error: object) => unknown
  errorType?: ErrorType
  additionalContext?: Record<string, unknown>
}

type LoggingErrorBoundaryState = {
  hasError?: boolean
  error?: object
}

export default LoggingErrorBoundary
