import { useEffect, useState } from 'react'
import canUseDom from 'lib/canUseDom'

const getPageHeight = () => {
  if (!canUseDom()) {
    return '100vh'
  }

  const value =
    (window && window.innerHeight) ||
    (document &&
      document.documentElement &&
      document.documentElement.clientHeight)

  return `${value}px`
}

const usePageHeight = () => {
  const [pageHeight, setHeight] = useState(getPageHeight())

  useEffect(() => {
    const handleResize = () => {
      setHeight(getPageHeight())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return pageHeight
}

export default usePageHeight
