// we disabled the no-restricted-imports because this is the component configure to replace the NextSeo
// eslint-disable-next-line no-restricted-imports
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { indexLocales, noIndexPaths } from './noIndex'
import HeadDomainUrls from '../head-domain-urls/HeadDomainUrls'

const Seo = ({ noindex, ...props }: { noindex?: boolean }) => {
  const { asPath, locale } = useRouter()

  const isNonIndexedPath = (path) =>
    noIndexPaths.includes('/'.concat(path.split('/')[1]))
  const isNonIndexedLocale = (value) => !indexLocales.includes(value)

  // if the `noindex` by locale/path check is `true`, then we want to apply that
  // else we want to apply the `noindex` passed in as props
  const appliedNoIndex =
    (isNonIndexedLocale(locale) && isNonIndexedPath(asPath)) || noindex

  return (
    <>
      <HeadDomainUrls />
      <NextSeo {...props} noindex={appliedNoIndex} />
    </>
  )
}

export default Seo
