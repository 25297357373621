import { useQueryResult } from 'components/generic/query-result-handler'
import PropTypes from 'prop-types'
import GET_HELPSCOUT_CONFIGS from './GetHelpscoutConfigs'
import { BeaconProvider } from './BeaconContext'

const Beacons = ({ children }) => {
  const { data } = useQueryResult(GET_HELPSCOUT_CONFIGS, {
    ssr: false, // The Beacon JS is lazily loaded on the client side, so only fetch the config there too
    dataKey: 'helpScoutConfigs',
  })

  return <BeaconProvider helpScoutBeacons={data}>{children}</BeaconProvider>
}

Beacons.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Beacons
