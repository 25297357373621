import PropTypes from 'prop-types'
import canUseDom from 'lib/canUseDom'
import Script from 'next/script'
import getConfig from 'next/config'
import { getIterableDistinctId, setIterableCookie } from './iterableTracking'

const { publicRuntimeConfig } = getConfig()

const IterableScript = ({ onLoad }) => {
  return (
    <>
      <Script
        id="iterable-js"
        data-testid="iterable-js"
        strategy="afterInteractive"
        src="https://js.iterable.com/analytics.js"
        onLoad={() => {
          // _iaq is the iterable sdk object
          if (canUseDom() && window._iaq) {
            window._iaq.push(['account', publicRuntimeConfig.ITERABLE_API_KEY])

            const distinctId = getIterableDistinctId()
            if (!distinctId) {
              setIterableCookie()
            }
            onLoad()
          }
        }}
      />
      <Script
        id="iterable-utils-js"
        type="text/javascript"
        src="https://js.iterable.com/iterableUtils.js"
      />
    </>
  )
}

IterableScript.propTypes = {
  onLoad: PropTypes.func.isRequired,
}

export default IterableScript
