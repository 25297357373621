import { themes, colors } from '@resident-advisor/design-system'
import light from './light'
import opacity from './opacity'

export default {
  ...light,

  subNav: {
    backgroundColor: colors.grey['80'],
    fontWeight: themes.light.fontWeights.normal,
    linkColor: colors.grey['10'],
    bottom: '0px',
  },
  sideNav: {
    backgroundColor: colors.grey[80],
    borderColor: colors.grey[50],
    item: {
      hoverColor: colors.white,
      borderColor: colors.coral,
      selectedColor: colors.white,
      defaultColor: colors.grey[80],
    },
  },
  sideModal: {
    backgroundColor: colors.white,
    opacity: opacity.proModalBackground,
    textColor: 'primary',
  },
  ticketStatistic: {
    maxValueColor: colors.grey[60],
  },
}
