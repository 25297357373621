import PropTypes from 'prop-types'
import BeaconButton from './BeaconButton'
import usePageBeacon from './usePageBeacon'

const PageBeaconWrapper = ({ children }) => {
  const { showBeacon } = usePageBeacon()

  return (
    <>
      {showBeacon && <BeaconButton />}
      {children}
    </>
  )
}

PageBeaconWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageBeaconWrapper
